import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import TodaySales from "./TodaySales";
import YesterdaySales from "./YesterdaySales";
import ThisWeekSales from "./ThisWeekSales";
import ThisMonthSales from "./ThisMonthSales";
import "./Dashboard.css";

function Dashboard() {
  const [todaySale, setTodaySale] = useState(null);
  const [yesterdaySale, setYesterdaySale] = useState(null);
  const [thisWeekSale, setThisWeekSale] = useState(null);
  const [thisMonthSale, setThisMonthSale] = useState(null);

  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function getAndSetDashboardSaleInfo() {
      const { data } = await axiosInstance.get(
        "/customers/orders/totalSaleInfoDayMonthWeekly"
      );
      setTodaySale(data.today);
      setYesterdaySale(data.yesterday);
      setThisWeekSale(data.thisWeek);
      setThisMonthSale(data.thisMonth);
    }
    getAndSetDashboardSaleInfo();
  }, [axiosInstance]);

  return (
    <div className="dashboard_crud_holder">
      <div className="dashboard">
        <TodaySales todaySale={todaySale} />
        <YesterdaySales yesterdaySale={yesterdaySale} />
        <ThisWeekSales thisWeekSale={thisWeekSale} />
        <ThisMonthSales thisMonthSale={thisMonthSale} />
      </div>
    </div>
  );
}

export default Dashboard;
