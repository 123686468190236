import React from "react";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import { RiCaravanLine, RiCoupon4Line, RiPriceTag2Line } from "react-icons/ri";
import BackButton from "../../Partials/Elements/BackButton/BackButton";

function OrderScreen() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "40px",
        gap: "20px",
      }}
    >
      <div>
        <BackButton />
      </div>
      <NavCardList numOfCards={"six"}>
        <NavCard cardName={"Delivery Fees"} navCardLink="/deliveryFees"><RiCaravanLine /></NavCard>
        <NavCard cardName={"Discount"} navCardLink="/discount"><RiCoupon4Line /></NavCard>
        <NavCard cardName={"Vats"} navCardLink="/vats"><RiPriceTag2Line /></NavCard>
      </NavCardList>
    </div>
  );
}

export default OrderScreen;
