import axios from "axios";
import { useEffect, useState } from "react";
import {
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiGiftLine,
  RiRepeatLine,
  RiTruckLine,
} from "react-icons/ri";
// import { useSelector } from "react-redux"
import { useParams } from "react-router-dom";
import { ORDERS_API, ORDERS_STATUS_API } from "../../../../Utilities/APIs/APIs";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ViewOrder from "../ViewOrder";
import OrderDelivered from "./OrderDelivered";
import CancelOrder from "./CancelOrder";
import { takaFormatter } from "../../../../Utilities/Formatter";
import useLevels from "../../../../Utilities/Permissions/useLevels";
import AppContext from "../../../../Context/AppContext";
import { useContext } from "react";
import AuthContext from "../../../../Context/AuthContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";

function OrdersFromStatus() {
  const [orders, setOrders] = useState(null);

  // console.log(orders)
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showProcessingOrder, setShowProcessingOrder] = useState(false);
  const [showCancelOrder, setShowCancelOrder] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const { employee } = useContext(AuthContext);
  const { branchManager } = useLevels();
  const { status } = useParams();
  const axiosInstance = useAxiosInstance();

  const { myBranch, pendingOrders, triggerFetchRequest } =
    useContext(AppContext);
  const branchId = myBranch?._id;
  // const [isLoadingBranchId, setIsLoadingBranchId] = useState(true);

  const [isLoadingBranchId, setIsLoadingBranchId] = useState(true);
  const allOrders = orders?.reverse();

  useEffect(() => {
    if (branchId !== null && branchId !== undefined) {
      setIsLoadingBranchId(true);
      async function fetchAndSetOrders() {
        try {
          const { data } = await axiosInstance.get(
            "customers/orders/allOrdersByStatus/" + status + "/" + branchId
          );
          setOrders(data);
        } finally {
          setIsLoadingBranchId(false); // Set loading state to false after fetching
        }
      }
      fetchAndSetOrders();
    } else {
      setIsLoadingBranchId(false); // Set loading state to false when branchId is not present
      async function fetchAndSetOrders() {
        try {
          const { data } = await axiosInstance.get(
            "customers/orders/getAllOrdersByStatusForSuperAdmin/" + status
          );
          setOrders(data);
        } finally {
          setIsLoadingBranchId(false); // Set loading state to false after fetching
        }
      }
      fetchAndSetOrders();
    }
  }, [
    axiosInstance,
    status,
    branchId,
    toggleFetch,
    pendingOrders,
    toggleFetch,
  ]);

  function handleClose() {
    setShowModal(false);
    setShowViewSection(false);
    setShowProcessingOrder(false);
    setShowCancelOrder(false);
  }

  function buttonIcon() {
    switch (status) {
      case "processing":
        return <RiGiftLine />;

      case "toBeDelivered":
        return <RiTruckLine />;

      case "shipped":
        return <RiCheckboxCircleLine />;

      default:
        return <RiRepeatLine />;
    }
  }

  async function handleStatusChange(id) {
    switch (status) {
      case "processing":
        await axiosInstance.patch(
          ORDERS_STATUS_API + "toBeDeliveredFromProcessing/" + id,
          ""
        );
        triggerFetchRequest();
        handleClose();

        return;

      case "toBeDelivered":
        await axiosInstance.patch(
          ORDERS_STATUS_API + "shippedFromToBeDelivered/" + id,
          ""
        );
        triggerFetchRequest();
        handleClose();

        return;

      case "shipped":
        await axiosInstance.patch(
          ORDERS_STATUS_API + "completedFromShipped/" + id,
          ""
        );
        triggerFetchRequest();
        handleClose();

        return;

      default:
        return console.log("Status Not Changed");
    }
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`${status?.toUpperCase()} Orders (${
              orders?.length
            } in total)`}</h1>
          </div>
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Date" />
            <CRUDth th="Time" />
            <CRUDth th="Customer Name" />
            {/* <CRUDth th='Area' /> */}
            <CRUDth th="Total" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {allOrders?.map((order) => (
              <CRUDTableRow key={order._id}>
                <ShortTextCell
                  text={new Date(order?.createdAt).toDateString()}
                />
                <ShortTextCell
                  text={
                    new Date(order?.createdAt)
                      .toLocaleTimeString()
                      .slice(0, 4) +
                    new Date(order?.createdAt).toLocaleTimeString().slice(7)
                  }
                />
                <ShortTextCell text={order?.customer?.name} />
                {/* <ShortTextCell text={order?.area?.name} /> */}
                <ShortTextCell text={takaFormatter(order?.grandTotal)} />
                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={order._id}
                    setTargetID={setTargetID}
                  />
                  {/* <CRUDButton handleClick={() => navigate('/order_items_from_order/' + order._id)}>
                                        <RiListSettingsLine />
                                    </CRUDButton> */}
                  {branchManager && (
                    <>
                      {(status === "processing" ||
                        status === "toBeDelivered" ||
                        status === "shipped") && (
                        <CRUDButton
                          handleClick={() => {
                            setShowModal(true);
                            setShowProcessingOrder(true);
                            setTargetID(order._id);
                          }}
                        >
                          {buttonIcon()}
                        </CRUDButton>
                      )}
                      {status !== "completed" && status !== "canceled" && (
                        <CRUDButton
                          handleClick={() => {
                            setShowModal(true);
                            setShowCancelOrder(true);
                            setTargetID(order._id);
                          }}
                        >
                          <RiCloseCircleLine />
                        </CRUDButton>
                      )}
                    </>
                  )}

                  {/* {
                                        (status === 'processing' || status === 'toBeDelivered' || status === 'shipped') &&
                                        <CRUDButton handleClick={() => handleStatusChange(order._id)}>
                                            {buttonIcon()}
                                        </CRUDButton>
                                    } */}
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Order`}
          view
        >
          {showViewSection && (
            <ViewOrder
              toggleFetch={toggleFetch}
              targetID={targetID}
              employee={employee}
              triggerFetch={triggerFetchRequest}
            />
          )}
        </Modal>
      )}

      {showModal && showProcessingOrder && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            // (showProcessingOrder && `Send to kitchen`)
            (status === "processing" && `Send to kitchen`) ||
            (status === "toBeDelivered" && `Delivered Now`) ||
            (status === "shipped" && `Completed Order`)
          }
        >
          <OrderDelivered
            handleStatusChange={handleStatusChange}
            targetID={targetID}
            setShowModal={setShowModal}
            setShowProcessingOrder={setShowProcessingOrder}
          />
        </Modal>
      )}
      {showModal && showCancelOrder && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showCancelOrder && `Cancel Order`}
        >
          <CancelOrder
            targetID={targetID}
            employee={employee}
            setShowModal={setShowModal}
            setShowCancelOrder={setShowCancelOrder}
            triggerFetch={triggerFetchRequest}
          />
        </Modal>
      )}
    </>
  );
}

export default OrdersFromStatus;
