import SmallSideBarItem from "./SmallSideBarItem/SmallSideBarItem";

import {
  RiArchiveDrawerLine,
  RiBarChartLine,
  RiGroupLine,
  RiHome2Line,
  RiOrderPlayLine,
  RiRestaurantLine,
  RiSettings2Line,
} from "react-icons/ri";

import "./SideBar.css";
import useLevels from "../../../../Utilities/Permissions/useLevels";
import OrderRequests from "./OrderRequests/OrderRequests";
import AppContext from "../../../../Context/AppContext";
import { useContext } from "react";

function SideBar() {
  const { superAdmin, owner, branchManager, employee } = useLevels();
  const { myBranch } = useContext(AppContext);

  console.log(myBranch);
  return (
    <>
      <section className="small_sidebar">
        <div className="container">
          {(employee || branchManager) && (
            <SmallSideBarItem
              link="/dashboard"
              dataTip="Dashboard"
              dataFor="dashboard"
            >
              <RiHome2Line />
            </SmallSideBarItem>
          )}

          {(owner || superAdmin) && (
            <>
              <SmallSideBarItem
                link="/"
                dataTip="Dashboard"
                dataFor="dashboard"
              >
                <RiHome2Line />
              </SmallSideBarItem>

              <SmallSideBarItem
                link="/reports"
                dataTip="Reports"
                dataFor="Reports"
              >
                <RiBarChartLine />
              </SmallSideBarItem>

              <SmallSideBarItem
                link="/restaurants"
                dataTip="Restaurants"
                dataFor="Restaurants"
              >
                <RiRestaurantLine />
              </SmallSideBarItem>

              <SmallSideBarItem
                link="/itemsScreen"
                dataTip="Classification"
                dataFor="Classification"
              >
                <RiArchiveDrawerLine />
              </SmallSideBarItem>
              {/* <SmallSideBarItem link="/fees" dataTip="Fees" dataFor="Fees">
                <RiOrderPlayLine />
              </SmallSideBarItem> */}
            </>
          )}
          {(owner || superAdmin || branchManager) && (
            <>
              <OrderRequests
                link="/orderScreens"
                dataTip="Orders"
                dataFor="Orders"
              />
            </>
          )}

          {branchManager && (
            <SmallSideBarItem
              link={`/branchCategory/${myBranch?._id}`}
              dataTip="Branch"
              dataFor="Branch"
            >
              <RiRestaurantLine />
            </SmallSideBarItem>
          )}

          {(owner || superAdmin) && (
            <>
              <SmallSideBarItem
                link="/usersScreen"
                dataTip="Users"
                dataFor="Users"
              >
                <RiGroupLine />
              </SmallSideBarItem>

              <SmallSideBarItem
                link="/settingsScreen"
                dataTip="Settings"
                dataFor="settings"
              >
                <RiSettings2Line />
              </SmallSideBarItem>
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default SideBar;
