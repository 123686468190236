
import { MANAGE_EMPLOYEE_API } from '../../../../Utilities/APIs/APIs'
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance'
// import { useSelector } from 'react-redux';
function RemoveEmployee({
  setShowModal, setShowRemoveEmployee, targetID, triggerFetch,isCurrent
}) {


    const axiosInstance = useAxiosInstance()

  async function handleRemove(e) {
      e.preventDefault()
      const updatedIsCurrent = !isCurrent; 

      const isCurrentData = { isCurrent: updatedIsCurrent };
    const response = await axiosInstance.patch(MANAGE_EMPLOYEE_API + 'superAdminOwnerRemoveEmployee/' + targetID, isCurrentData)

    if (response) {
      setShowModal(false);
      triggerFetch()
    }
  }
  return (
    <div className='modal_delete_content'>
      <p>
        Are you sure you want to {isCurrent ? "Archive" : "Unarchive"} this employee?

      </p>

      <div className="modal_group_buttons">
        <button
          className="delete"
          onClick={handleRemove}
        >{isCurrent ? "Archive" : "Unarchive"}</button>

        <button className="cancel" onClick={() => {
          setShowModal(false);
          setShowRemoveEmployee(false);
          
        }}>Cancel</button>
      </div>

    </div>
  )
}

export default RemoveEmployee