import { RiCaravanLine, RiCoupon4Line, RiFeedbackFill, RiMapPinLine, RiPriceTag2Line, RiProfileLine, RiSettings2Fill } from "react-icons/ri"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"
import BackButton from "../../Partials/Elements/BackButton/BackButton"

function SettingsScreen() {
    return (
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "30px", height: "89dvh" }}>
            <div style={{ paddingBottom: "20px" }}>
                <BackButton />
            </div>
            <NavCardList numOfCards={'eight'} >
                <NavCard cardName={'App Settings'} navCardLink={'/appSettings'} ><RiSettings2Fill /></NavCard>
                <NavCard cardName={'Hero Sliders'} navCardLink='/heroSliders' ><RiProfileLine /></NavCard>
                <NavCard cardName={'Emerald Zones'} navCardLink={'/emeraldZones'} ><RiMapPinLine /></NavCard>
                <NavCard cardName={'Feedback'} navCardLink={'/feedback'} ><RiFeedbackFill /></NavCard>
                <NavCard cardName={"Delivery Fees"} navCardLink="/deliveryFees"><RiCaravanLine /></NavCard>
                <NavCard cardName={"Discount"} navCardLink="/discount"><RiCoupon4Line /></NavCard>
                <NavCard cardName={"Vats"} navCardLink="/vats"><RiPriceTag2Line /></NavCard>
            </NavCardList>
        </div>



    )
}

export default SettingsScreen