import { useState, useEffect, useContext } from "react"
// import { useSelector } from "react-redux"
import axios from "axios"

// import { CLIENTS_API, CONTENT_GENRES_API } from "../../../Utilities/APIs/APIs"

// import { FiImage } from "react-icons/fi"
import {useParams } from "react-router-dom"

import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton"
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell"
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
// import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton"
// import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton"
import Modal from "../../../Partials/Elements/Modal/Modal"
import CRUDDeleteBoilerPlate from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate"
import { COMBO_ITEMS_API } from "../../../../Utilities/APIs/APIs"
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton"
import ViewComItem from "./ViewComItem"
import CreateComboItem from "./CreateComboItem"
import AuthContext from "../../../../Context/AuthContext"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem"



function ComboItems() {
    const {parentID} =useParams()

    const [comboItems, setComboItems] = useState(null)
    const [restaurantItems, setRestaurantItems] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [showDeleteSection, setShowDeleteSection] = useState(false)
    const [toggleFetch, setToggleFetch] = useState(false)

    const { employee } = useContext(AuthContext)
    // const navigate = useNavigate()

    const axiosInstance = useAxiosInstance()

    useEffect(() => {
        async function fetchAndSetComboItems() {
            const { data } = await axiosInstance.get(COMBO_ITEMS_API + 'getItemsFromCombo/' + parentID)
            setComboItems(data.comboItems)
            setRestaurantItems(data.restaurantItems)
        }
        fetchAndSetComboItems()
    }, [toggleFetch, parentID, axiosInstance])

    function handleClose() {
        setShowModal(false);
        setShowCreateForm(false);
        setShowViewSection(false);
        setShowUpdateForm(false);
        setShowDeleteSection(false);
    }
    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }
    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <h1 className="heading">{`Combo Items (${comboItems?.length} in total)`}</h1>
                    <CreateButton
                        screenTopicSingular='Combo Item'
                        setShowModal={setShowModal}
                        setShowCreateForm={setShowCreateForm}
                    />
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Name' />
                        <CRUDth th='Image' />
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {comboItems?.map(comboItem => (
                            <CRUDTableRow key={comboItem._id}>
                                <ShortTextCell text={comboItem.item.name} />
                                <ImageCell imgSrc={comboItem.item.image} />

                                <td className="action_button_cell">
                                    <ViewButton
                                        setShowModal={setShowModal}
                                        setShowViewSection={setShowViewSection}
                                        targetID={comboItem.item._id}
                                        setTargetID={setTargetID}
                                    />
                                    {/* <EditButton
                                        setShowModal={setShowModal}
                                        setShowUpdateForm={setShowUpdateForm}
                                        targetID={comboItem._id}
                                        setTargetID={setTargetID}
                                    /> */}
                                    <DeleteButton
                                        setShowModal={setShowModal}
                                        setShowDeleteSection={setShowDeleteSection}
                                        targetID={comboItem._id}
                                        setTargetID={setTargetID}
                                    />

                                    {/* <CRUDButton
                                        handleClick={() => navigate('/featuredItems/' + item._id)}
                                    >
                                        <FiImage />
                                    </CRUDButton> */}

                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal &&
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (showCreateForm && `Create Combo Item`) ||
                        (showUpdateForm && `Update Combo Item`) ||
                        (showDeleteSection && `Delete Combo Item`)
                    }
                >
                    {
                        showCreateForm &&
                        <CreateComboItem
                            employee={employee}
                            setShowCreateForm={setShowCreateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                            restaurantItems={restaurantItems}
                            combo={parentID}
                           
                        />
                    }
                    {/* {
                        showUpdateForm &&
                        <UpdateItem
                            employee={employee}
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    } */}
                    {
                        showDeleteSection &&
                        <DeleteItem
                        api={COMBO_ITEMS_API}
                        targetID={targetID}
                        employee={employee}
                        setShowModal={setShowModal}
                        setShowDeleteSection={setShowDeleteSection}
                        hasName
                        triggerFetch={triggerFetch}
                    />
                    }
                </Modal>
            }

            {showModal && showViewSection &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showViewSection && `View Combo Item`)
                    }
                >
                    <ViewComItem
                        targetID={targetID}
                        employee={employee}
                    />
                </Modal>
            }
        </>
    )
}

export default ComboItems