import { AREAS_API, DISTRICTS_API } from "../../../Utilities/APIs/APIs";
import PcNSCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/NSCRUD/PcNSCRUD";

function Areas() {
  return (
    <PcNSCRUD
      // parentChild props
      parentDBField='district'

      // common props
      api={AREAS_API}
      screenTopicSingular='Area'
      screenTopicPlural='Areas'

      // crud header props
      showTotalInHeading

      // select props
      selectApi={DISTRICTS_API}
      selectType='parent-child'
      selectDBField='district'
      selectName='District'
    />
  )
}

export default Areas