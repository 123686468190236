import { useEffect, useState } from "react"
import axios from "axios"
import Switch from "react-switch";
import { CATEGORIES_API, ITEMS_API, MANAGE_ITEM_API } from "../../../../Utilities/APIs/APIs"
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import LongTextInput from "../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";


function UpdateItem({

    targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {

    const [categories, setCategories] = useState(null)
    const [category, setCategory] = useState(null)
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('')
    const [image, setImage] = useState('')
    // const [isAvailable, setIsAvailable] = useState(false)
    // const [isVisible, setIsVisible] = useState(false)
    const [isCombo, setIsCombo] = useState(false)
    const axiosInstance = useAxiosInstance();


    useEffect(() => {
        async function fetchAndSetCategories() {
            const { data } = await axiosInstance.get(CATEGORIES_API)
            setCategories(data)
        }
        async function fetchAndSetItem() {
            const { data } = await axiosInstance.get(MANAGE_ITEM_API + 'getSingleItem/' + targetID)
            setCategory(data.category);
            setName(data.name);
            setDescription(data.description);
            setPrice(data.price);
            setImage(data.image);
            // setIsAvailable(data.isAvailable);
            // setIsVisible(data.isVisible);
            setIsCombo(data.isCombo);
        }
        fetchAndSetItem()
        fetchAndSetCategories()

    }, [targetID, axiosInstance])

    // const handleIsAvailable = nextChecked => {
    //     setIsAvailable(nextChecked);
    // };
    // const handleIsVisible = nextChecked => {
    //     setIsVisible(nextChecked);
    // };
    const handleIsCombo = nextChecked => {
        setIsCombo(nextChecked);
    };

    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = new FormData()

        itemData.append('category', category)
        itemData.append('name', name)
        itemData.append('description', description)
        itemData.append('price', price)
        itemData.append('image', image)
        // itemData.append('isAvailable', isAvailable)
        // itemData.append('isVisible', isVisible)
        itemData.append('isCombo', isCombo)


        const response = await axiosInstance.patch(MANAGE_ITEM_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false)
            setShowModal(false);
            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <SelectInput value={category} setState={setCategory}>
                <SelectOption optionValue={''} optionText={`Select Category`} />
                {categories && categories.map(cat => (
                    <SelectOption key={cat._id} optionValue={cat._id} optionText={cat.name} />
                ))}
            </SelectInput>
            <ShortTextInput
                label={`name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />
            <LongTextInput
                label={`Description`}
                value={description}
                placeholder={`Enter Description`}
                setState={setDescription}
            />
            <ShortTextInput
                label={`Price`}
                value={price}
                placeholder={`Enter Price`}
                setState={setPrice}
            />
            <ImageInput
                fieldId='1'
                state={image}
                setState={setImage}
                allowUpdateImage
            >
                Upload Card Image
            </ImageInput>
            {/* <div>
                <label className="input_field_label">
                    Is Available?</label>
                <Switch
                    onChange={handleIsAvailable}
                    checked={isAvailable}
                    className="react-switch"
                />
            </div>
            <div>
                <label className="input_field_label">
                    Is Visible?</label>
                <Switch
                    onChange={handleIsVisible}
                    checked={isVisible}
                    className="react-switch"
                />
            </div> */}
            <div>
                <label className="input_field_label">
                    Is Combo?</label>
                <Switch
                    onChange={handleIsCombo}
                    checked={isCombo}
                    className="react-switch"
                />
            </div>


            <FormSubmitButton text='Update Item' />
        </Form>
    )
}

export default UpdateItem