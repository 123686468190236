import { useEffect, useState } from "react";
import "./ViewOrder.css";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import OrderItem from "../PerformanceReports/MonthlyRoports/OrderItem";
function ViewOrder({ employee, targetID, triggerFetch, toggleFetch }) {

  const [activeTab, setActiveTab] = useState(1);
  const [order, setOrder] = useState(null);
  const [items, setItems] = useState(null);
  const [orderItems, setOrderItems] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetOrder() {
      const { data } = await axiosInstance.get(
        "/customers/orders/getSingleOrder/" + targetID
      );
      setOrder(data[0]);
    }

    async function fetchOrderItems() {
      const { data } = await axiosInstance.get(
        "/customers/orders/orderItemsFromOrder/" + targetID
      );
      setOrderItems(data);
    }

    // async function fetchAndSetItems() {
    //   const { data } = await axiosInstance.get(
    //     "/items/controlItems/manageItem"
    //   );
    //   setItems(data);
    // }
    fetchAndSetOrder();
    fetchOrderItems();
    // fetchAndSetItems();
  }, [targetID, toggleFetch]);

  return (
    <div className="view_order">
      <div className="navtab_action">
        <div className="nav_tab">
          <div>
            <button
              type="button"
              className={activeTab === 1 ? "active" : ""}
              onClick={() => setActiveTab(1)}
            >
              <span>Details</span>
            </button>
          </div>
          <div>
            <button
              type="button"
              className={activeTab === 2 ? "active" : ""}
              onClick={() => setActiveTab(2)}
            >
              <span>Order Items</span>
            </button>
          </div>
        </div>
      </div>
      <div className={`nav_content ${activeTab === 1 ? "active" : ""}`}>
        <h1>Order ID</h1>
        <p>{order?.orderID}</p>

        <h1>Branch</h1>
        <p>{order?.branch?.name}</p>

        <h1>Customer Name</h1>
        <p>{order?.customer?.name}</p>

        <h1>Contact</h1>
        <p>{order?.customer?.mobile}</p>

        <h1>Area</h1>
        <p>{order?.areaString}</p>

        <h1>Full Address</h1>
        <p>{order?.fullAddressString}</p>

        <h1>Date</h1>
        <p>{new Date(order?.createdAt).toDateString()}</p>
      </div>
      <div className={`nav_content ${activeTab === 2 ? "active" : ""}`}>
        <OrderItem
          orderItems={orderItems}
          order={order}
          // items={items}
          triggerFetch={triggerFetch}
        />
      </div>
    </div>
  );
}

export default ViewOrder;
