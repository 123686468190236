import React, { useState } from 'react'
import useAxiosInstance from '../../../Utilities/Hooks/useAxiosInstance';
import { HERO_SLIDERS_API, SOCIAL_LINKS_API } from '../../../Utilities/APIs/APIs';
import Form from '../../Partials/Layouts/Forms/Form';
import SelectInput from '../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput';
import SwitchInput from '../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput';
import ShortTextInput from '../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput';
import FormSubmitButton from '../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton';

function CreateSocialMediaScreen({

    restaurantID, restaurants, employee, setShowCreateForm, setShowModal, triggerFetch

}) {
    const [name, setName] = useState(null);
    const [link, setLink] = useState(null);
    const [visibility, setVisibility] = useState(false);
    const axiosInstance = useAxiosInstance();

    async function handleSubmit(e) {
        e.preventDefault();

        const itemData = new FormData();

        
        itemData.append('restaurant', restaurantID)
        // itemData.append("restaurant", restaurant);
        itemData.append("name", name);
        itemData.append("visibility", visibility);
        itemData.append("link", link);

        const response = await axiosInstance.post(SOCIAL_LINKS_API, itemData);

        if (response) {
            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch();
        }
    }
    return (
        <Form onSubmit={handleSubmit} hasImage>
       
            <ShortTextInput
                label={`name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />
            <SwitchInput
                label={"visibility"}
                toggleSwitch={() => setVisibility((prevState) => !prevState)}
                checked={visibility}
            />
            <ShortTextInput
                label={`Link`}
                value={link}
                placeholder={`Enter Link`}
                setState={setLink}
            />

            <FormSubmitButton text="Create Restaurant Area" />
        </Form>
    )
}

export default CreateSocialMediaScreen
