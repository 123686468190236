import { useState, useEffect } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { BRANCH_CATEGORY_API } from "../../../../../Utilities/APIs/APIs";

function ViewBranchCategory({
  //common props
  targetID,
}) {
  const [branchCategory, setBranchCategory] = useState(null);
  console.log("TCL: restaurant", branchCategory);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetRestaurant() {
      const { data } = await axiosInstance.get(
        BRANCH_CATEGORY_API + "singleBranchCategoryData/" + targetID
      );

      console.log(data, "Category Data");
      setBranchCategory(data);
    }
    fetchAndSetRestaurant();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {branchCategory && (
        <p>
          <h1>Category Name</h1>
          <p>{branchCategory?.category.name}</p>

          <h1>Precedence</h1>
          <p>{branchCategory?.precedence}</p>
          {/* <h1>Active</h1>
                    <p>{branchCategory?.isActive}</p> */}
        </p>
      )}
    </div>
  );
}

export default ViewBranchCategory;
