import BINCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/BINCRUD/BINCRUD";
import {  MANAGE_APP_SETTING_API } from './../../../Utilities/APIs/APIs';

function AppSettings() {
  return (
    <BINCRUD
      // common props
      api={MANAGE_APP_SETTING_API}
      screenTopicSingular='App Setting'
      screenTopicPlural='App Settings'


      // crud header props
      showTotalInHeading


      // crud props
      allowCreate
      allowUpdate
      // allowDelete


      // field props
      booleanDBField={'isCategoryFeatured'}
      booleanName={'Is Category Featured?'}
    />
  )
}

export default AppSettings