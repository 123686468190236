import { useContext, useEffect } from "react";
import { useState } from "react";
import useAxiosInstance from "./../../../../Utilities/Hooks/useAxiosInstance";
import AuthContext from "./../../../../Context/AuthContext";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "./../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import {
  BRANCH_API,
  MANAGE_BRANCH_API,
  RESTAURANTS_API,
} from "../../../../Utilities/APIs/APIs";
import CreateBranch from "./CreateBranch";
import { useNavigate, useParams } from "react-router-dom";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import ViewBranh from "./ViewBranh";
import UpdateBranch from "./UpdateBranch";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import { FiImage } from "react-icons/fi";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";
import FilterSelect from "../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import {  FaLockOpen } from "react-icons/fa";
import { HiLockClosed } from "react-icons/hi";

const Branch = () => {
  const { parentID } = useParams();

  const [branches, setBranches] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AuthContext);

  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("active");

  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetBranches() {
      const { data } = await axiosInstance.get(
        `${MANAGE_BRANCH_API}getBranchesByRestaurant/${parentID}?filter=${filter}`
      );
      setBranches(data.branches);
    }
    fetchAndSetBranches();
  }, [toggleFetch, axiosInstance, parentID, filter]);

  async function branchTakingOrder(id, isBranchOpen) {
    console.log(isBranchOpen);
    const { data } = await axiosInstance.patch(
      `${MANAGE_BRANCH_API}/isBranchOpen/${id}`,
      { isBranchOpen: !isBranchOpen }
    );
    triggerFetch();
    console.log(data);
  }

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Branch (${branches?.length} in total)`}</h1>
          </div>

          <FilterSelect filter={filter} setFilter={setFilter} />
          <CreateButton
            screenTopicSingular="Branch"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Cover Image" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {branches?.length > 0 &&
              branches?.map((branch) => (
                <CRUDTableRow key={branch._id}>
                  <ShortTextCell text={branch.name} />
                  <ImageCell imgSrc={branch.coverImage} />

                  <td className="action_button_cell">
                    <ViewButton
                      setShowModal={setShowModal}
                      setShowViewSection={setShowViewSection}
                      targetID={branch._id}
                      setTargetID={setTargetID}
                    />

                    <EditButton
                      setShowModal={setShowModal}
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={branch._id}
                      setTargetID={setTargetID}
                    />
                    {/* <DeleteButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={branch._id}
                      setTargetID={setTargetID}
                    /> */}
                    <ArchiveButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={branch._id}
                      isArchive={branch?.isActive}
                      setTargetID={setTargetID}
                      setArchive={setArchive}
                    />
                    <CRUDButton
                      dataTip="Branch Details"
                      dataFor="Branch Details Item"
                      handleClick={() =>
                        navigate("/branchScreen/" + branch._id)
                      }
                    >
                      <FiImage />
                    </CRUDButton>
                    {!branch?.isBranchOpen && (
                      <CRUDButton
                        dataTip=" Open Branch"
                        dataFor=" Open Branch Item"
                        handleClick={() => {
                          branchTakingOrder(branch?._id, branch?.isBranchOpen);
                        }}
                      >
                        <HiLockClosed />
                      </CRUDButton>
                    )}

                    {branch?.isBranchOpen && (
                      <CRUDButton
                        dataTip=" Close Branch"
                        dataFor="Close Branch Item"
                        handleClick={() => {
                          branchTakingOrder(branch?._id, branch?.isBranchOpen);
                        }}
                      >
                        <FaLockOpen />
                      </CRUDButton>
                    )}
                  </td>
                </CRUDTableRow>
              ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Branch`) ||
            (showUpdateForm && `Update Branch`) ||
            (showDeleteSection && `Archive Branch`)
          }
        >
          {showCreateForm && (
            <CreateBranch
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              restaurantId={parentID}
            />
          )}
          {showUpdateForm && (
            <UpdateBranch
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              restaurantId={parentID}
            />
          )}

          {showDeleteSection && (
            
            <ArchiveItem
              api={MANAGE_BRANCH_API + "archiveRestaurant/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Branch`}
        >
          <ViewBranh targetID={targetID} employee={employee} />
        </Modal>
      )}
    </>
  );
};

export default Branch;
