import BackButton from '../../../../../../Elements/BackButton/BackButton'
import './CRUDHeading.css'

function CRUDHeading({ screenTopicPlural, showTotalInHeading, totalItems }) {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
      <BackButton />
      <h1 className="heading">
        {screenTopicPlural +
          (showTotalInHeading && totalItems ? ` (${totalItems} in total)` : '')
        }
      </h1>
    </div>

  )
}

export default CRUDHeading