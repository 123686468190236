import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { RiGridLine, RiMapPin2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { MANAGE_CUSTOMERS_API } from "../../../../Utilities/APIs/APIs";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ViewCustomer from "./ViewCustomer";
import AuthContext from "../../../../Context/AuthContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";
import Search from "../../../Partials/Elements/Search/Search";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import FilterSelect from "../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import Pagination from "../../../Partials/Layouts/Pagination/Pagination";

function Customers() {
  const [customers, setCustomers] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [archive, setArchive] = useState(null);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [filter, setFilter] = useState("active");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const { employee } = useContext(AuthContext);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCustomers() {
      const { data } = await axiosInstance.get(
        MANAGE_CUSTOMERS_API +
          "getAllCustomers?search=" +
          search +
          "&filter=" +
          filter +
          "&currentPage=" +
          currentPage
      );
      setCustomers(data.customers);
      setTotalItems(data.totalItems);
    }
    fetchAndSetCustomers();
  }, [axiosInstance, search, toggleFetch, filter, currentPage]);

  function handleClose() {
    setShowModal(false);
    setShowViewSection(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  console.log("showViewSection", showViewSection);
  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Customers (${customers?.length} in total)`}</h1>
          </div>
          <Search value={search} setState={setSearch} />
          <FilterSelect filter={filter} setFilter={setFilter} />
        </div>
        <CRUDTable isPaginated={true}>
          <CRUDTableHeader>
            <CRUDth th="Customer Name" />
            <CRUDth th="Email" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {customers?.map((customer) => (
              <CRUDTableRow key={customer._id}>
                <ShortTextCell text={customer.name} />
                <ShortTextCell text={customer.email} />
                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={customer._id}
                    setTargetID={setTargetID}
                  />
                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={customer._id}
                    isArchive={customer?.isActive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />

                  <CRUDButton
                    dataTip="Orders"
                    dataFor="Orders"
                    handleClick={() =>
                      navigate("/customerOrders/" + customer._id)
                    }
                  >
                    <RiGridLine />
                  </CRUDButton>
                  <CRUDButton
                    dataTip="Address"
                    dataFor="Address Item"
                    handleClick={() =>
                      navigate("/addressesFromCustomer/" + customer._id)
                    }
                  >
                    <RiMapPin2Line />
                  </CRUDButton>
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
        <Pagination
          totalItems={totalItems}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Item`}
        >
          {showViewSection && (
            <ViewCustomer targetID={targetID} employee={employee} />
          )}
        </Modal>
      )}
      {showModal && showDeleteSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showDeleteSection && `Archive Customer`}
        >
          {showDeleteSection && (
            <ArchiveItem
              api={MANAGE_CUSTOMERS_API + "archiveCustomer/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default Customers;
