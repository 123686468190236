import { useState, useEffect } from "react"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { IMAGE_URL, MANAGE_BRANCH_API } from "../../../../Utilities/APIs/APIs";

function ViewBranh({

    //common props
    targetID, employee

}) {

    const [restaurant, setRestaurant] = useState(null)
    console.log("TCL: restaurant", restaurant)
    const axiosInstance = useAxiosInstance();


    useEffect(() => {
        async function fetchAndSetRestaurant() {
            const { data } = await axiosInstance.get(MANAGE_BRANCH_API + 'singleBranchData/' + targetID)
            setRestaurant(data);
        }
        fetchAndSetRestaurant()
    }, [targetID, axiosInstance])

    return (

        <div className="crud_view_content">

            {restaurant &&
                <p>
                    <h1>Name</h1>
                    <p>{restaurant?.branch?.name}</p>
                    <h1>Cuisine</h1>
                    <p>{restaurant?.branch?.cuisine}</p>
                    <h1>Description</h1>
                    <p>{restaurant?.branch?.description}</p>
                    <h1>Home Card Image</h1>
                    <img src={IMAGE_URL + restaurant?.branch?.homeCardImage} alt="" />
                    <h1>Cover Image</h1>
                    <img src={IMAGE_URL + restaurant?.branch?.coverImage} alt="" />
                    <h1>Cover Image 2</h1>
                    <img src={IMAGE_URL + restaurant?.branch?.coverImage2} alt="" />
                    <h1>Logo</h1>
                    <img src={IMAGE_URL + restaurant?.branch?.logo} alt="" />
                </p>
            }
        </div>
    )
}

export default ViewBranh