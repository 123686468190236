
import { useState, useEffect } from "react"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { FEATURED_ITEMS_API, IMAGE_URL } from "../../../../Utilities/APIs/APIs";



function ViewFeaturedItem({

    //common props
    targetID, employee

}) {

    const [item, setItem] = useState(null)
    const axiosInstance = useAxiosInstance();


    useEffect(() => {
        async function fetchAndSetItem() {
            const { data } = await axiosInstance.get(FEATURED_ITEMS_API + 'getSingleFeaturedItems/' + targetID)
            setItem(data);
        }
        fetchAndSetItem()
    }, [targetID, axiosInstance])

// console.log("item is",item)

    return (

        <div className="crud_view_content">

            {item &&
                <>
                    <h1>Branch</h1>
                    <p>{item.branchItem.branch?.name}</p>
                    <h1>Name</h1>
                    <p>{item.branchItem.item?.name}</p>
                    <h1>Item Image</h1>
                    <img src={IMAGE_URL + item.branchItem.item?.image} alt="Food"/>
                    <h1>Description</h1>
                    <p>{item.branchItem.item?.description}</p>
                    <h1>Category</h1>
                    <p>{item.branchItem.category?.name}</p>
                   
                </>
            }
        </div>
    )
}

export default ViewFeaturedItem