import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../Context/AuthContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { DISCOUNT_API } from "../../../../Utilities/APIs/APIs";
import Modal from "../../../Partials/Elements/Modal/Modal";
import ViewDiscount from "./ViewDiscount";
import CRUDDeleteBoilerPlate from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate";
import CreateDiscount from "./CreateDiscount";
import UpdateDiscount from "./UpdateDiscount";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";

const Discount = () => {
  const [discounts, setDiscounts] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetDiscount() {
      const { data } = await axiosInstance.get(DISCOUNT_API);
      setDiscounts(data);
    }
    fetchAndSetDiscount();
  }, [toggleFetch, axiosInstance]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Discount (${discounts?.length} in total)`}</h1>
          </div>

          {discounts && discounts.length > 0 ? (
            " "
          ) : (
            <CreateButton
              screenTopicSingular="Discount"
              setShowModal={setShowModal}
              setShowCreateForm={setShowCreateForm}
            />
          )}
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Amount" />
            {/* <CRUDth th="IsApplicable" /> */}
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {discounts?.map((discount) => (
              <CRUDTableRow key={discount._id}>
                <ShortTextCell text={discount.amount} />
                {/* <ShortTextCell text={discount.isApplicable} /> */}

                <td className="action_button_cell">
                  {/* <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={discount._id}
                    setTargetID={setTargetID}
                  /> */}
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={discount._id}
                    setTargetID={setTargetID}
                  />
                  {/* <DeleteButton
                            setShowModal={setShowModal}
                            setShowDeleteSection={setShowDeleteSection}
                            targetID={discount._id}
                            setTargetID={setTargetID}
                        /> */}
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create discount`) ||
            (showUpdateForm && `Update discount`) ||
            (showDeleteSection && `Delete discount`)
          }
        >
          {showCreateForm && (
            <CreateDiscount
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateDiscount
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={DISCOUNT_API}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View discount`}
        >
          <ViewDiscount targetID={targetID} employee={employee} />
        </Modal>
      )}
    </>
  );
};

export default Discount;
