import React from 'react'
import { RiProfileLine } from 'react-icons/ri'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'

function HomeScreen() {
  return (
    <NavCardList numOfCards={'six'}>
      <NavCard cardName={'Hero Sliders'} navCardLink='/heroSliders' ><RiProfileLine /></NavCard>
    </NavCardList>
  )
}

export default HomeScreen