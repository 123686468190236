import { RiMap2Line } from "react-icons/ri";
import { DISTRICTS_API, DIVISIONS_API } from "../../../Utilities/APIs/APIs";
import PcNSCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/NSCRUD/PcNSCRUD";

function Districts() {
  return (
    <PcNSCRUD

      // parentChild props
      parentDBField='division'

      // common props
      api={DISTRICTS_API}
      screenTopicSingular='District'
      screenTopicPlural='Districts'

      // crud header props
      showTotalInHeading

      // action button props
      extraActionButton
      extraActionButtonHandleClick='/areas'
      extraActionButtonChildren={<RiMap2Line />}

      // select props
      selectApi={DIVISIONS_API}
      selectType='parent-child'
      selectDBField='division'
      selectName='Division'
    />
  )
}

export default Districts