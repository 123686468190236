import React, { useContext, useEffect, useState } from "react";

import { MANAGE_EMPLOYEE_API } from "../../../../Utilities/APIs/APIs";
import AuthContext from "../../../../Context/AuthContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function CreateEmployee({
  setShowModal,
  setShowCreateEmployee,
  targetID,
  triggerFetch,
}) {
  
  const { employee } = useContext(AuthContext);

  const [employeeBranch,setEmployeeBranch] = useState("")
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetEmployeeBranch() {
      const { data } = await axiosInstance.get(
        "/manageEmployees/employeeBranches/getSingleEmployeeBranch/" + targetID);
      setEmployeeBranch(data);
    }

    fetchAndSetEmployeeBranch();


  }, [ axiosInstance, targetID]);


  async function handleCreateAdmin(e) {
    e.preventDefault();
    const response = await axiosInstance.patch(
      MANAGE_EMPLOYEE_API +
        "createManagerToEmployeeBySuperAdminOwner/" +
        targetID,
      {}
    );

    if (response) {
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <div className="modal_delete_content">
      <p style={{ textAlign: "center" }}>
        Are you sure you want to remove Branch manager from {employeeBranch.branch?.name}?
      </p>

      <div className="modal_group_buttons ">
        <button className="delete" onClick={handleCreateAdmin}>
         Make Employee
        </button>

        <button
          className="cancel"
          onClick={() => {
            setShowModal(false);
            setShowCreateEmployee(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default CreateEmployee;
