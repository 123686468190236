import React from 'react'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import { RiBlazeLine, RiGridLine } from 'react-icons/ri'
import BackButton from '../../Partials/Elements/BackButton/BackButton'

function ItemsScreen() {
  return (

    <div style={{display:"flex",flexDirection:"column",marginTop:"40px",gap:"20px"}}>
    <div>
      <BackButton />
    </div>
    <NavCardList numOfCards={'six'}>
    {/* <NavCard cardName={'Categories'} navCardLink='/categories' ><RiCollageLine /></NavCard> */}
    {/* <NavCard cardName={'Featured Items'} navCardLink='/featuredItems' ><RiGridLine /></NavCard> */}
    <NavCard cardName={'Menu Categories'} navCardLink='/categories' ><RiBlazeLine /></NavCard>
    <NavCard cardName={'Featured Branch Items'} navCardLink='/featuredItems' ><RiGridLine /></NavCard>
    {/* <NavCard cardName={'Featured Branch Combos'} navCardLink='/FeaturedCombos' ><RiGridLine /></NavCard> */}
  
  </NavCardList>
    </div>


    
  )
}

export default ItemsScreen