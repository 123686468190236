

function ThisWeekSales({thisWeekSale}) {
  return (
    <div className="card">
        <p>Total This Week Sales:</p>
        <h3>${thisWeekSale}</h3>
    </div> 
  )
}

export default ThisWeekSales