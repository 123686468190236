import React, { useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { VATS_API } from "../../../../Utilities/APIs/APIs";
import Form from "../../../Partials/Layouts/Forms/Form";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import SwitchInput from "../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

const CreateVat = ({
  employee,
  setShowCreateForm,
  setShowModal,
  triggerFetch,
}) => {
  const [amount, setAmount] = useState("");
  const [isApplicable, setIsApplicable] = useState(false);

  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("amount", amount);
    itemData.append("isApplicable", isApplicable);

    const response = await axiosInstance.post(VATS_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <NumberInput
          label={`Amount`}
          value={amount}
          placeholder={`Enter Amount`}
          setState={setAmount}
        />
        <SwitchInput
          label={"isApplicable"}
          toggleSwitch={() => setIsApplicable((prevState) => !prevState)}
          checked={isApplicable}
        />

        <FormSubmitButton text="Create Vat" />
      </Form>
    </div>
  );
};

export default CreateVat;
