import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { FEATURED_COMBOS_API, FEATURED_ITEMS_API, MANAGE_ITEM_API } from "../../../../Utilities/APIs/APIs";
import Form from "../../../Partials/Layouts/Forms/Form";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";


const CreateFeaturedCombos = ({
  employee,
  setShowCreateForm,
  setShowModal,
  triggerFetch,
}) => {
  const [item, setItem] = useState('')
  const [precedence, setPrecedence] = useState("");
  const axiosInstance = useAxiosInstance();
  const [items, setItems] = useState(null)

  useEffect(() => {
    async function fetchAndSetItem() {
      const { data } = await axiosInstance.get(MANAGE_ITEM_API);
      setItems(data);
    }
    fetchAndSetItem();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("item", item);
    itemData.append("precedence", precedence);

    const response = await axiosInstance.post(FEATURED_COMBOS_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <SelectInput value={item} setState={setItem}>
        <SelectOption optionValue={""} optionText={`Select Category`} />
        {items &&
          items.map((restaurantItem) => (
            <SelectOption
              key={restaurantItem._id}
              optionValue={restaurantItem._id}
              optionText={restaurantItem.name}
            />
          ))}
      </SelectInput>
      <NumberInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Enter Precedence`}
        setState={setPrecedence}
      />

      <FormSubmitButton text="Create Combo Item" />
    </Form>
  );
};

export default CreateFeaturedCombos;
