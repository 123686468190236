import React from "react";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import { RiGroup2Line, RiGroupLine } from "react-icons/ri";
import BackButton from "../../Partials/Elements/BackButton/BackButton";

function UsersScreen() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "40px",
        gap: "20px",
      }}
    >
      <div>
        <BackButton />
      </div>
      <NavCardList numOfCards={"four"}>
        <NavCard cardName={"Customers"} navCardLink="/customers">
          <RiGroup2Line />
        </NavCard>
        <NavCard cardName={"Employees"} navCardLink="/employeesScreen">
          <RiGroupLine />
        </NavCard>
      </NavCardList>
    </div>
  );
}

export default UsersScreen;
