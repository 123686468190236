
import { useEffect, useState } from "react";
import { EMERALD_AREAS_API, } from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

const ViewEmeraldAreas = ({
  //common props
  targetID,
  employee,
}) => {
  const [name, setName] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetVat() {
      const { data } = await axiosInstance.get( EMERALD_AREAS_API + "getSingleEmeraldAreas/" + targetID );
      setName(data);
    }
    fetchAndSetVat();
  }, [targetID, axiosInstance]);
  return (
    <div className="crud_view_content">
      {name && (
        <>
          <h1>Name</h1>
          <p>{name?.name}</p>
        </>
      )}
    </div>
  );
};

export default ViewEmeraldAreas;
