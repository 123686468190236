import { BsTrash2 } from "react-icons/bs";
import { takaFormatter } from "../../../../Utilities/Formatter";
import "../../OrderScreens/OrderItems/OrderItems.css";
import { useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import toast from "react-hot-toast";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import { FiTrash2 } from "react-icons/fi";

function OrderItem({ orderItems, order, items, triggerFetch }) {
  const axiosInstance = useAxiosInstance();

  async function handleCancelItem(id) {
    if (orderItems.length === 1) {
      return toast.error("At least one item should be in the order");
    }

    await axiosInstance.delete(
      `/customers/orders/deleteSingleItemFromOrder/${id}`
    );
    triggerFetch();
    toast.success("Item Deleted");
  }

  return (
    <div>
      <div className="order_items_wrapper">
        <div className="order_items item_header">
          <div className="item">
            <h1>Item</h1>
          </div>
          <div className="item">
            <h1>Quantity</h1>
          </div>
          <div className="item">
            <h1>Unit Price</h1>
          </div>
          <div className="item">
            <h1>Total</h1>
          </div>
          <div className="item">
            <h1>Cancel item</h1>
          </div>
        </div>
        {orderItems?.map((i) => (
          <div className="order_items" key={i._id}>
            <div className="item">
              {/* <p>{items?.find(item => item._id === i.item._id)?.name} </p> */}
              <p>{i.item?.name} </p>
            </div>
            <div className="item">
              <p>{i.quantity}</p>
            </div>
            <div className="item">
              <p>{takaFormatter(i.unitPrice)}</p>
            </div>
            <div className="item">
              <p>{takaFormatter(i.total)}</p>
            </div>
            <div className="item">
              <CRUDButton
                dataTip="Cancel Item"
                dataFor="Cancel Item"
                style={{
                  padding: "5px",
                  borderRadius: "5px",
                }}
                handleClick={() => handleCancelItem(i?._id)}
                deleteButton
              >
                <FiTrash2 />
              </CRUDButton>
            </div>
          </div>
        ))}
      </div>

      <div className="calculation">
        <h6>Total</h6>
        <h6>{takaFormatter(order?.total.toFixed(2))}</h6>
      </div>
      <div className="calculation">
        <h6>Delivery Fee</h6>
        <h6>{takaFormatter(order?.deliveryFee.toFixed(2))}</h6>
      </div>
      <div className="calculation">
        <h6>Sub Total</h6>
        <h6>{takaFormatter(order?.subtotal.toFixed(2))}</h6>
      </div>
      <div className="calculation">
        <h6>VAT</h6>
        <h6>{takaFormatter(order?.vat.toFixed(2))}</h6>
      </div>
      <div className="calculation">
        <h6>Discount</h6>
        <h6>{takaFormatter(order?.discount?.toFixed(2) || 0)}</h6>
      </div>
      <div className="calculation grand-total">
        <h6>Grand Total</h6>
        <h6>{takaFormatter(order?.grandTotal.toFixed(2))}</h6>
      </div>
    </div>
  );
}

export default OrderItem;
