import { useLocation } from "react-router-dom";

function OrderDelivered({
  setShowModal,
  setShowProcessingOrder,
  targetID,
  handleStatusChange,
}) {
  const { pathname } = useLocation();

  const text = {
    processing: "send this to kitchen?",
    toBeDelivered: "send this to delivery?",
    shipped: "complete this order?",
  };

  return (
    <div className="modal_delete_content">
      <p style={{ textAlign: "center" }}>
        Are you sure you want to
        {` ${text[pathname.slice(18)]}`}?
      </p>

      <div className="modal_group_buttons">
        <button
          className="delete green_btn"
          // style={{backgroundColor: 'green'}}
          onClick={() => handleStatusChange(targetID)}
        >
          Yes
        </button>

        <button
          className="cancel"
          style={{ color: "#fff" }}
          onClick={() => {
            setShowModal(false);
            setShowProcessingOrder(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default OrderDelivered;
