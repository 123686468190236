import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../Context/AuthContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { DELIVERY_FEES_API } from "../../../../Utilities/APIs/APIs";
import Modal from "../../../Partials/Elements/Modal/Modal";
import ViewFeaturedItem from "../../ItemsScreen/FeaturedItems/ViewFeaturedItem";
import CRUDDeleteBoilerPlate from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate";
import UpdateFeaturedItem from "../../ItemsScreen/FeaturedItems/UpdateFeaturedItem";
import CreateFeaturedItem from "../../ItemsScreen/FeaturedItems/CreateFeaturedItem";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import ViewDeliveryFee from "./ViewDeliveryFee";
import CreateDeliveryFee from "./CreateDeliveryFee";
import UpdateDeliveryFee from "./UpdateDeliveryFee";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";

const DeliveryFees = () => {
  const [deliveryFees, setDeliveryFees] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AuthContext);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetDeliveryFees() {
      const { data } = await axiosInstance.get(DELIVERY_FEES_API);
      setDeliveryFees(data);
    }
    fetchAndSetDeliveryFees();
  }, [toggleFetch, axiosInstance]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Delivery Fees (${deliveryFees?.length} in total)`}</h1>
          </div>

          {deliveryFees && deliveryFees.length > 0 ? (
            ""
          ) : (
            <CreateButton
              screenTopicSingular="Delivery Fees"
              setShowModal={setShowModal}
              setShowCreateForm={setShowCreateForm}
            />
          )}
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Amount" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {deliveryFees?.map((fee) => (
              <CRUDTableRow key={fee._id}>
                <ShortTextCell text={fee.name} />
                <ShortTextCell text={fee.amount} />

                <td className="action_button_cell">
                  {/* <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={fee._id}
                    setTargetID={setTargetID}
                  /> */}
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={fee._id}
                    setTargetID={setTargetID}
                  />
                  {/* <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={fee._id}
                    setTargetID={setTargetID}
                 /> */}
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Delivery Fees`) ||
            (showUpdateForm && `Update Delivery Fees`) ||
            (showDeleteSection && `Delete Delivery Fees`)
          }
        >
          {showCreateForm && (
            <CreateDeliveryFee
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateDeliveryFee
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {/* {showDeleteSection && (
            <CRUDDeleteBoilerPlate
              api={DELIVERY_FEES_API}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )} */}
        </Modal>
      )}

      {/* {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Delivery Fees`}
        >
          <ViewDeliveryFee  targetID={targetID} employee={employee} />
        </Modal>
      )} */}
    </>
  );
};

export default DeliveryFees;
