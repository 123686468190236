import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { BRANCH_ITMS_API, FEATURED_ITEMS_API, MANAGE_ITEM_API } from "../../../../Utilities/APIs/APIs";
import Form from "../../../Partials/Layouts/Forms/Form";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";


const CreateFeaturedItem = ({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
}) => {

  const [branchItem, setBranchItem] = useState('')
  const [branchItems, setBranchItems] = useState(null)
  const [precedence, setPrecedence] = useState("");
  const axiosInstance = useAxiosInstance();


  useEffect(() => {
    async function fetchAndSetHeroSliders() {
      const { data } = await axiosInstance.get(BRANCH_ITMS_API);
      setBranchItems(data);
    }
    fetchAndSetHeroSliders();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("branchItem", branchItem);
    itemData.append("precedence", precedence);

    const response = await axiosInstance.post(FEATURED_ITEMS_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }


  return (
    <Form onSubmit={handleSubmit} hasImage>
      <SelectInput value={branchItem} setState={setBranchItem}>
        <SelectOption optionValue={""} optionText={`Select Item`} />
        {branchItems &&
          branchItems.map((branch) => (
            <SelectOption
              key={branch._id}
              optionValue={branch._id}
              optionText={branch.item?.name}
            />
          ))}
      </SelectInput>
      <NumberInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Enter Precedence`}
        setState={setPrecedence}
      />

      <FormSubmitButton text="Create Featured Item" />
    </Form>
  );
};

export default CreateFeaturedItem;
