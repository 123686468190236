import { useContext } from 'react'
import AppContext from '../../../Context/AppContext'
import './PendingOrders.css'
import { Link } from 'react-router-dom';
// import { playSound } from '../../../Utilities/Sound';
function PendingOrders() {
    const { pendingOrders , setNewOrder} = useContext(AppContext)

    // playSound()
    return (
        <div className='pending_orders'>
            <p>You have</p>
            <h1>{pendingOrders?.length}</h1>
            <p>Pending {pendingOrders?.length > 1 ? 'Requests' : 'Request'} </p>
            <Link to='/ordersFromStatus/processing'>
            <button
                className="delete"
                onClick={() => {
                    setNewOrder(false)
                  }}
            >
                View Pending Requests
            </button>
            </Link>
        </div>
    )
}

export default PendingOrders