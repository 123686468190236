import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { VATS_API } from "../../../../Utilities/APIs/APIs";

const ViewVat = ({
  //common props
  targetID,
  employee,
}) => {
  const [vat, setVat] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetVat() {
      const { data } = await axiosInstance.get(
        VATS_API + "getSingleVats/" + targetID
      );
      setVat(data);
    }
    fetchAndSetVat();
  }, [targetID, axiosInstance]);
  return (
    <div className="crud_view_content">
      {vat && (
        <>
          <h1>Amount</h1>
          <p>{vat?.amount}</p>
          <h1>IsApplicable</h1>
          <p>{vat.isApplicable === true ? 'True': 'False'}</p>
        </>
      )}
    </div>
  );
};

export default ViewVat;
