import { useState, useEffect, useContext } from "react";
// import { useSelector } from "react-redux";


import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CRUDDeleteBoilerPlate from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate";
import { ORDERS_API } from "../../../../Utilities/APIs/APIs";
import ViewOrder from "../../OrderScreens/ViewOrder";
import { takaFormatter } from "../../../../Utilities/Formatter";
import AuthContext from "../../../../Context/AuthContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";

function TimeRangeReports() {
  const [timeRangeReports, setTimeRangeReports] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // const month = startDate.substring(5)
  // const year = startDate.substring(0,4)
const axiosInstance  = useAxiosInstance()
  const { employee } = useContext(AuthContext);
  // const navigate = useNavigate()
  const ordersTotalGrand = timeRangeReports && timeRangeReports.length >0 && timeRangeReports?.map((order) => order.grandTotal);
  // const grandTotal = ordersTotalGrand?.reduce(
  //   (previousValue, currentValue) => previousValue + currentValue,
  //   0
  // );

  const grandTotal = ordersTotalGrand
  ? ordersTotalGrand.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    )
  : 0;


  useEffect(() => {
    setStartDate(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDay() < 10 ? '0' + new Date().getDay() : new Date().getDay()}`)
    setEndDate(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDay() < 10 ? '0' + new Date().getDay() : new Date().getDay()}`)
}, [])

  useEffect(() => {
   
    if (startDate && endDate) {
      async function fetchAndTimeRangsReports() {
        const { data } = await axiosInstance.get(
           "/customers/orders/getTimeRangeOrders/" + startDate + "/" + endDate
        );
        setTimeRangeReports(data);
      }
      fetchAndTimeRangsReports();
    }
  }, [toggleFetch,axiosInstance, startDate, endDate]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }
  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <BackButton/>
          <h1 className="heading">{` Orders (${
            timeRangeReports?.length ? timeRangeReports?.length : "0"
          } in total)`}</h1>
          <div className="order_date">
            <h1 className="input_field_label">Start Date</h1>
            <input
              className="input_field"
              type="date"
              id="bdaymonth"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="order_date">
            <h1 className="input_field_label">End Date</h1>
            <input
              className="input_field"
              type="date"
              id="bdaymonth"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <h1 className="heading">{`Total Revenue (${
            grandTotal ? takaFormatter(grandTotal) : "0"
          })`}</h1>
        </div>
        <CRUDTable>
          <CRUDTableHeader>
          <CRUDth th='Branch' />
            <CRUDth th="Customer" />
            <CRUDth th="Status" />
            <CRUDth th='Amount' />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {timeRangeReports && timeRangeReports.length > 0 &&  timeRangeReports?.map((monthlyReport) => (
              <CRUDTableRow key={monthlyReport._id}>
                <ShortTextCell text={monthlyReport.branch?.name} />
                <ShortTextCell text={monthlyReport.customer?.name} />
                <ShortTextCell text={monthlyReport.orderStatus} />
                <ShortTextCell text={takaFormatter(monthlyReport.total)} />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={monthlyReport._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Item`) ||
            (showUpdateForm && `Update Item`) ||
            (showDeleteSection && `Delete Item`)
          }
        >
          {showDeleteSection && (
            <CRUDDeleteBoilerPlate
              api={ORDERS_API}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Order`}
        >
          {showViewSection && (
            <ViewOrder targetID={targetID} employee={employee} />
          )}
        </Modal>
      )}
    </>
  );
}

export default TimeRangeReports;
