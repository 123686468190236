import { useContext, useEffect } from "react";
import { useState } from "react";
import AuthContext from "../../../../../../Context/AuthContext";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { BRANCH_AREAS_API, MANAGE_BRANCH_API } from "../../../../../../Utilities/APIs/APIs";
import CreateButton from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import Modal from "../../../../../Partials/Elements/Modal/Modal";
import DeleteItem from "../../../../../Partials/Layouts/DeleteItem/DeleteItem";
import CreateBranchArea from "./CreateBranchArea";
import UpdateBranchArea from "./UpdateBranchArea";
import { useParams } from "react-router-dom";
import BackButton from "../../../../../Partials/Elements/BackButton/BackButton";
import FilterSelect from "../../../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import ArchiveButton from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import ArchiveItem from "../../../../../Partials/Layouts/ArchiveItem/ArchiveItem";

const BranchArea = () => {
  const { branchID } = useParams();

  const [branches, setBranches] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AuthContext);
  const [branch, setBranch] = useState(null)

  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("active");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetBranch() {
        const { data } = await axiosInstance.get(MANAGE_BRANCH_API + 'singleBranchData/' + branchID)
        setBranch(data);
    }
    fetchAndSetBranch()
}, [targetID, axiosInstance])

  useEffect(() => {
    async function fetchAndSetBranches() {
      const { data } = await axiosInstance.get(
        `${BRANCH_AREAS_API}getAllBranchAreaByBranch/${branchID}?filter=${filter}`
      );
      setBranches(data);
    }
    fetchAndSetBranches();
  }, [toggleFetch, axiosInstance, branchID, filter]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`${branch && branch?.branch?.name} Branch Areas (${branches?.length} in total)`}</h1>
          </div>
          <FilterSelect filter={filter} setFilter={setFilter} />

          <CreateButton
            screenTopicSingular="Branch Area"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Emerald Area" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {branches?.length > 0 &&
              branches?.map((branch) => (
                <CRUDTableRow key={branch._id}>
                  <ShortTextCell text={branch?.emeraldArea?.name} />

                  <td className="action_button_cell">
                    {/* <ViewButton
                      setShowModal={setShowModal}
                      setShowViewSection={setShowViewSection}
                      targetID={branch._id}
                      setTargetID={setTargetID}
                    /> */}
                    <EditButton
                      setShowModal={setShowModal}
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={branch._id}
                      setTargetID={setTargetID}
                    />
                    {/* <DeleteButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={branch._id}
                      setTargetID={setTargetID}
                    /> */}

                    <ArchiveButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={branch._id}
                      isArchive={branch?.isActive}
                      setTargetID={setTargetID}
                      setArchive={setArchive}
                    />
                  </td>
                </CRUDTableRow>
              ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Branch Area`) ||
            (showUpdateForm && `Update Branch Area`) ||
            (showDeleteSection && `Archive Branch Area`)
          }
        >
          {showCreateForm && (
            <CreateBranchArea
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              branchID={branchID}
            />
          )}
          {showUpdateForm && (
            <UpdateBranchArea
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              branchID={branchID}
            />
          )}

          {showDeleteSection && (
            // <DeleteItem
            //   api={BRANCH_AREAS_API}
            //   targetID={targetID}
            //   employee={employee}
            //   setShowModal={setShowModal}
            //   setShowDeleteSection={setShowDeleteSection}
            //   hasName
            //   triggerFetch={triggerFetch}
            // />

            <ArchiveItem
              api={BRANCH_AREAS_API + "archiveBranchArea/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}

      {/* {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Branch Area`}
        >
          <ViewBranchArea targetID={targetID} employee={employee} />
        </Modal>
      )} */}
    </>
  );
};

export default BranchArea;
