import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { BRANCH_AREAS_API, EMERALD_AREAS_API } from "../../../../../../Utilities/APIs/APIs";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import SelectInput from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";



function CreateBranchArea({ branchID, setShowCreateForm, setShowModal, triggerFetch}) {
    
    const [emeraldAreas, SetEmeraldAreas] = useState(null)
    const [emeraldArea, setEmeraldArea] = useState('')
    
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetCategories() {
           
            const { data } = await axiosInstance.get(EMERALD_AREAS_API + 'getNotAddedEmeraldAreas?branch=' + branchID)
            SetEmeraldAreas(data)
        }
        fetchAndSetCategories()
    }, [axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = new FormData()

        itemData.append('emeraldArea', emeraldArea)
        itemData.append('branch', branchID)

        const response = await axiosInstance.post(BRANCH_AREAS_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <SelectInput value={emeraldArea} setState={setEmeraldArea}>
                <SelectOption optionValue={''} optionText={`Select Emerald Area`} />
                {emeraldAreas && emeraldAreas.map(cat => (
                    <SelectOption key={cat._id} optionValue={cat._id} optionText={cat.name} />
                ))}
            </SelectInput>
            <FormSubmitButton text='Create Branch Area' />
        </Form>
    )
}

export default CreateBranchArea