import axios from "axios";
import { useContext, useEffect, useState } from "react";
// import { useSelector } from "react-redux"
import {
  EMPLOYEES_API,
  MANAGE_EMPLOYEE_API,
  RESTAURANT_MANAGER_API,
} from "../../../../Utilities/APIs/APIs";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import { RiDeleteBin2Line, RiImage2Line, RiSubtractLine } from "react-icons/ri";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CreateEmployee from "../Employees/CreateEmployee";
import CreateRestaurantManager from "./CreateRestaurantManager";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import ViewEmployee from "../Employees/ViewEmployee";
import AuthContext from "../../../../Context/AuthContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";
import { FaArrowDown } from 'react-icons/fa';
import { MdRemoveCircleOutline } from 'react-icons/md';

function AllManagers() {
  const [allManagers, setAllManagers] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [showCreateEmployee, setShowCreateEmployee] = useState(false);
  const [showRestaurantManager, setShowRestaurantManager] = useState(false);
  const [branchManager, setBranchManager] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);

  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetAllManagers() {
      const { data } = await axiosInstance.get(
        "/manageEmployees/getAllManagers"
      );
      setAllManagers(data);
    }

    fetchAndSetAllManagers();


    async function fetchAndsetBranchManager() {
      const { data } = await axiosInstance.get("/manageEmployees/employeeBranches/getBranchByEmployee");
      setBranchManager(data);
    }

    fetchAndsetBranchManager();
  }, [employee.token, axiosInstance, toggleFetch]);


  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  function handleClose() {
    setShowModal(false);
    setShowRestaurantManager(false);
    setShowViewSection(false);
    setShowCreateEmployee(false);
  }


  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Managers (${allManagers?.length} in total)`}</h1>
          </div>
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Image" />
            <CRUDth th="Name" />
            <CRUDth th="Assigned to" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {allManagers?.map((m) => (
              <CRUDTableRow key={m._id}>
                <ImageCell imgSrc={m.dp} imgAlt={m.name} />
                <ShortTextCell text={m.name} />
                {/* <ShortTextCell text={branchManager?.branch} /> */}
                <ShortTextCell
                  text={
                    branchManager?.map((rm) => rm.employee)?.includes(m._id)
                      ? branchManager?.find((rm) => rm.employee === m._id)
                        ?.branch.name
                      : "Not Assigned"
                  }
                />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={m._id}
                    setTargetID={setTargetID}
                  />

                  <CRUDButton
                  dataTip='Make Employee'
                  dataFor='Make Employee Item'
                    handleClick={() => {
                      setShowModal(true);
                      setShowCreateEmployee(true);
                      setTargetID(m._id);
                    }}
                  >
                   {/* <FaArrowDown /> */}
                   <MdRemoveCircleOutline />
                  </CRUDButton>
                  <CRUDButton
                  dataTip='Branch Manager'
                  dataFor='Branch Manager Item'
                    handleClick={() => {
                      setShowModal(true);
                      setShowRestaurantManager(true);
                      setTargetID(m._id);
                    }}
                  >
                    <RiImage2Line />
                  </CRUDButton>
                </td>
              </CRUDTableRow>
            ))}

          </tbody>
        </CRUDTable>
      </div>
      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Employee`}
        >
          {showViewSection && (
            <ViewEmployee targetID={targetID} employee={employee} />
          )}
        </Modal>
      )}

      {showModal && showCreateEmployee && (
        <Modal
          handleClose={handleClose}
          modalHeading={`Make Employee To Branch Manager `}
        >
          {showCreateEmployee && (
            <CreateEmployee
              targetID={targetID}
              setShowModal={setShowModal}
              setShowCreateEmployee={setShowCreateEmployee}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}
      {showModal && showRestaurantManager && (
        <Modal
          handleClose={handleClose}
          modalHeading={`Create Branch Manager `}
        >
          {showRestaurantManager && (
            <CreateRestaurantManager
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowRestaurantManager={setShowRestaurantManager}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default AllManagers;
