


import { useEffect, useState } from "react"
import Switch from "react-switch";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { BRANCH_CATEGORY_API } from "../../../../../Utilities/APIs/APIs";
import Form from "../../../../Partials/Layouts/Forms/Form";
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import NumberInput from "../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";




function CreateBranchCategory({ branchID, setShowCreateForm, setShowModal, triggerFetch}) {
    
    const [categories, setCategories] = useState(null)
    const [category, setCategory] = useState('')
    const [precedence,setPrecedence] = useState("")

    const axiosInstance = useAxiosInstance();

  

    useEffect(() => {
        async function fetchAndSetCategories() {
           
            const { data } = await axiosInstance.get(BRANCH_CATEGORY_API + 'getNotAddedCategories' + '?branch=' + branchID)
            setCategories(data)
        }

        fetchAndSetCategories()
    }, [axiosInstance])


    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = new FormData()

        itemData.append('branch', branchID)
        itemData.append("precedence",precedence)
        itemData.append('category', category)

        const response = await axiosInstance.post(BRANCH_CATEGORY_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            

            <SelectInput value={category} setState={setCategory}>
                <SelectOption optionValue={''} optionText={`Select Categories`} />
                {categories && categories?.map(cat => (
                    <SelectOption key={cat._id} optionValue={cat._id} optionText={cat?.name} />
                ))}
            </SelectInput>

            <NumberInput
                label={`Precedence`}
                value={precedence}
                placeholder={`Enter Precedence`}
                setState={setPrecedence}
            />
          
            <FormSubmitButton text='Create Branch Category' />
        </Form>
    )
}

export default CreateBranchCategory