import axios from "axios";
import { useContext, useMemo } from "react";
import AuthContext from "../../Context/AuthContext";

function useAxiosInstance() {
    const { employee, logout } = useContext(AuthContext)
    const axiosInstance = useMemo(() => {
        const instance = axios.create({
            baseURL: process.env.REACT_APP_BACKEND_URL + 'api/employeeApp/protected/',
            headers: {
                'Authorization': 'Bearer ' + employee?.token
            }
        });

        instance.interceptors.response.use(
            response => {
                return response;
            },
            error => {

                if (error.response && error.response.status === 401) {
                    logout();
                }
                return Promise.reject(error);
            }
        );

        return instance;
    }, [employee, logout]);

    return axiosInstance;

}

export default useAxiosInstance;



