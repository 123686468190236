import React, { useEffect, useState } from "react";
import { CATEGORIES_API } from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

const ViewCategory = ({
  //common props
  targetID,
  employee,
}) => {
  const axiosInstance = useAxiosInstance();

  const [categories, setCategories] = useState(null);
  useEffect(() => {
     async function fetchAndSetCategories() {
       const { data } = await axiosInstance.get(CATEGORIES_API + 'getSingleCategories/' + targetID);
       setCategories(data);
     }
     fetchAndSetCategories();
   }, [targetID, axiosInstance]);
   
  return (
    <div className="crud_view_content">
      {categories && (
        <>
          <h1>Name</h1>
          <p>{categories?.name}</p>
          <h1>Description</h1>
          <p>{categories?.description}</p>
          <h1>Precedence</h1>
          <p>{categories.precedence}</p>
        </>
      )}
    </div>
  );
};

export default ViewCategory;
