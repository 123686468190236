import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { DISCOUNT_API } from "../../../../Utilities/APIs/APIs";

const ViewDiscount = ({
  //common props
  targetID,
  employee,
}) => {
  const [discount, setDiscount] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetDiscount() {
      const { data } = await axiosInstance.get( DISCOUNT_API + "getSingleDiscounts/" + targetID);
      setDiscount(data);
    }
    fetchAndSetDiscount();
  }, [targetID, axiosInstance]);
    return (
    <div className="crud_view_content">
      {discount && (
        <>
          <h1>Amount</h1>
          <p>{discount?.amount}</p>
          <h1>IsApplicable</h1>
          <p>{discount.isApplicable  === true ? "True" : "False"}</p>
        </>
      )}
    </div>
  );
};

export default ViewDiscount;
