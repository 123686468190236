import React, { useEffect, useState } from "react";
import Form from "../../../Partials/Layouts/Forms/Form";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import LongTextInput from "../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput";
import { CATEGORIES_API } from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

const UpdateCategory = ({
  restaurantID,
  targetID,
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [precedence, setPrecedence] = useState("");

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCategoryInfo() {
        const { data } = await axiosInstance.get(CATEGORIES_API + 'getSingleCategories/' + targetID)
        setName(data.name);
        setDescription(data.description);
        setPrecedence(data.precedence)
    }
    fetchAndSetCategoryInfo()
}, [targetID, axiosInstance])

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("description", description);
    itemData.append("precedence", precedence);

    const response = await axiosInstance.patch(CATEGORIES_API  + targetID , itemData);

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <div>
      <Form onSubmit={handleSubmit}>

        <ShortTextInput
          label={`Name`}
          value={name}
          placeholder={`Enter Name`}
          setState={setName}
        />
        <LongTextInput
          label={`Description`}
          value={description}
          placeholder={`Enter Description`}
          setState={setDescription}
        />
        <NumberInput
          label={`Precedence`}
          value={precedence}
          placeholder={`Enter Precedence`}
          setState={setPrecedence}
        />

        <FormSubmitButton text="Update Category" />
      </Form>
    </div>
  );
};

export default UpdateCategory;
