import { useState, useEffect, useContext } from "react"
// import { useSelector } from "react-redux"
import axios from "axios"

import {useNavigate, useParams } from "react-router-dom"

import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import ImageCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell"
import ViewButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import Modal from "../../Partials/Elements/Modal/Modal"
import CRUDDeleteBoilerPlate from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate"
import { COMBO_ITEMS_API } from "../../../Utilities/APIs/APIs"
import ViewItem from "../ItemsScreen/Items/ViewItem"
import CRUDButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton"
import { FiImage } from "react-icons/fi"
import AuthContext from "../../../Context/AuthContext"
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance"



function ComboScreen() {
    const {parentID} =useParams()

    const [combos, setCombos] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [showDeleteSection, setShowDeleteSection] = useState(false)
    const [toggleFetch, setToggleFetch] = useState(false)

    const { employee } = useContext(AuthContext)
    const navigate = useNavigate()
    const axiosInstance = useAxiosInstance()

    useEffect(() => {
        async function fetchAndSetComboItems() {
            const { data } = await axiosInstance.get(COMBO_ITEMS_API + 'getCombosFromRestaurant/' + parentID)
            setCombos(data)
        }
        fetchAndSetComboItems()
    }, [toggleFetch, parentID, axiosInstance])

    function handleClose() {
        setShowModal(false);
        setShowCreateForm(false);
        setShowViewSection(false);
        setShowUpdateForm(false);
        setShowDeleteSection(false);
    }
    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }
    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <h1 className="heading">{`Combo (${combos?.length} in total)`}</h1>
                    {/* <CreateButton
                        screenTopicSingular='Combo'
                        setShowModal={setShowModal}
                        setShowCreateForm={setShowCreateForm}
                    /> */}
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Name' />
                        <CRUDth th='Image' />
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {combos?.map(combo => (
                            <CRUDTableRow key={combo._id}>
                                <ShortTextCell text={combo.name} />
                                <ImageCell imgSrc={combo.image} />

                                <td className="action_button_cell">
                                    <ViewButton
                                        setShowModal={setShowModal}
                                        setShowViewSection={setShowViewSection}
                                        targetID={combo._id}
                                        setTargetID={setTargetID}
                                    />
                                    {/* <EditButton
                                        setShowModal={setShowModal}
                                        setShowUpdateForm={setShowUpdateForm}
                                        targetID={comboItem._id}
                                        setTargetID={setTargetID}
                                    /> */}
                                    {/* <DeleteButton
                                        setShowModal={setShowModal}
                                        setShowDeleteSection={setShowDeleteSection}
                                        targetID={item._id}
                                        setTargetID={setTargetID}
                                    /> */}

                                    <CRUDButton
                                        handleClick={() => navigate('/comboItems/' + combo._id)}
                                    >
                                        <FiImage />
                                    </CRUDButton>

                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal &&
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (showCreateForm && `Create Combo`) ||
                        (showUpdateForm && `Update Combo`) ||
                        (showDeleteSection && `Delete Combo`)
                    }
                >
                    {/* {
                        showCreateForm &&
                        <CreateItem
                            employee={employee}
                            setShowCreateForm={setShowCreateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                            restaurantID={parentID}
                        />
                    } */}
                    {/* {
                        showUpdateForm &&
                        <UpdateItem
                            employee={employee}
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    } */}
                    {
                        showDeleteSection &&
                        <CRUDDeleteBoilerPlate
                            api={COMBO_ITEMS_API}
                            targetID={targetID}
                            employee={employee}
                            setShowModal={setShowModal}
                            setShowDeleteSection={setShowDeleteSection}
                            hasName
                            triggerFetch={triggerFetch}
                        />
                    }
                </Modal>
            }

            {showModal && showViewSection &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showViewSection && `View Combo Item`)
                    }
                >
                    <ViewItem
                        targetID={targetID}
                        employee={employee}
                    />
                </Modal>
            }
        </>
    )
}

export default ComboScreen