import { useEffect, useState } from "react";
import Switch from "react-switch";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { BRANCH_ITMS_API, MANAGE_ITEM_API, RESTAURANTS_API } from "../../../../../../Utilities/APIs/APIs";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import SelectInput from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function UpdateBranchItems({ targetID, branchID, setShowUpdateForm, setShowModal, triggerFetch,toggleFetch}) {

    const [items, setItems] = useState(null)
    const [item, setitem] = useState('')


    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetItem() {
            const { data } = await axiosInstance.get(MANAGE_ITEM_API)
            setItems(data)
        }
        fetchAndSetItem()
    }, [axiosInstance])

    useEffect(() => {
        async function fetchAndSetItem() {
            const { data } = await axiosInstance.get(RESTAURANTS_API + 'brances/branchItems/' + "singleBranchItemsData/" + targetID)
            setitem(data.branchItems?.item?._id)

        }
        fetchAndSetItem()
    }, [axiosInstance,toggleFetch,targetID])


    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = new FormData()

        itemData.append('item', item)
        itemData.append('branch', branchID)


        const response = await axiosInstance.patch(BRANCH_ITMS_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }
    }
    
    return (
        <Form onSubmit={handleSubmit} hasImage>
        <SelectInput value={item} setState={setitem}>
            <SelectOption optionValue={''} optionText={`Select Branch Items`} />
            {items && items.map(cat => (
                <SelectOption key={cat._id} optionValue={cat._id} optionText={cat.name} />
            ))}
        </SelectInput>
      
        <FormSubmitButton text='Update Branch Items' />
    </Form>
    )
}

export default UpdateBranchItems
