import React from 'react'

function NavCardBadge({number}) {
  return (
    <div className="order_nmbrs">
    <p>{number}</p>
  </div>
  )
}

export default NavCardBadge