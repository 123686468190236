

function TodaySales({todaySale}) {
  return (
     <div className="card">
        <p>Total Today Sales:</p>
        <h3>${todaySale}</h3>
    </div> 
    
  )
}

export default TodaySales