import { useEffect, useState } from "react";
import {
  
  EMERALD_ZONES_API,
} from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

const ViewEmeraldZones = ({
  //common props
  targetID,
  employee,
}) => {
  const [name, setName] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetVat() {
      const { data } = await axiosInstance.get(
        EMERALD_ZONES_API + "getSingleEmeraldZones/" + targetID
      );
      setName(data);
    }
    fetchAndSetVat();
  }, [targetID, axiosInstance]);
  return (
    <div className="crud_view_content">
      {name && (
        <>
          <h1>Name</h1>
          <p>{name?.name}</p>
        </>
      )}
    </div>
  );
};

export default ViewEmeraldZones;
