import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import { useParams } from "react-router-dom"
import { MANAGE_CUSTOMERS_API } from "../../../../../Utilities/APIs/APIs"
import BackButton from "../../../../Partials/Elements/BackButton/BackButton"
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import { takaFormatter } from "../../../../../Utilities/Formatter"
import Modal from "../../../../Partials/Elements/Modal/Modal"
import ViewOrder from "../../../OrderScreens/ViewOrder"


function CustomerOrders() {

    const [customerOrders, setCustomerOrders] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const [search, setSearch] = useState("")
    const { customerID } = useParams()

    // const { employee } = useContext(AuthContext)

    const axiosInstance = useAxiosInstance();


    useEffect(() => {

        async function fetchAndSetCustomers() {
            const { data } = await axiosInstance.get(MANAGE_CUSTOMERS_API + 'getSingleCustomerOrders/' + customerID)
            setCustomerOrders(data)
        }
        fetchAndSetCustomers()

    }, [axiosInstance, search])

    function handleClose() {
        setShowModal(false);
        setShowViewSection(false);
    }


    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                        <BackButton />
                        <h1 className="heading">{`Customer Orders (${customerOrders?.length} in total)`}</h1>
                    </div>
                    {/* <Search value={search} setState={setSearch} /> */}
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th="Date" />
                        <CRUDth th="Time" />
                        <CRUDth th="Total" />
                        <CRUDth th="Actions" />
                    </CRUDTableHeader>
                    <tbody>
                        {customerOrders?.map(customerOrder => (
                            <CRUDTableRow key={customerOrder._id}>
                                <ShortTextCell
                                    text={new Date(customerOrder?.createdAt).toDateString()}
                                />
                                <ShortTextCell
                                    text={
                                        new Date(customerOrder?.createdAt)
                                            .toLocaleTimeString()
                                            .slice(0, 4) +
                                        new Date(customerOrder?.createdAt).toLocaleTimeString().slice(7)
                                    }
                                />

                                <ShortTextCell text={takaFormatter(customerOrder?.grandTotal)} />
                                <td className="action_button_cell">
                                    <ViewButton
                                        setShowModal={setShowModal}
                                        setShowViewSection={setShowViewSection}
                                        targetID={customerOrder._id}
                                        setTargetID={setTargetID}
                                    />

                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal && (
                <Modal
                    handleClose={handleClose}
                    modalHeading={showViewSection && `View Order`}
                    view
                >
                    {showViewSection && (
                        <ViewOrder
                            targetID={targetID}

                        />
                    )}
                </Modal>
            )}
        </>
    )
}

export default CustomerOrders