import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'
import axios from 'axios'
import './App.css'

import NavBar from './Partials/Sections/NavBar/NavBar'
import SideBar from './Partials/Sections/SideBar/SideBar'
import Login from './Screens/AuthScreens/Login/Login'
import Dashboard from './Screens/Dashboard/Dashboard'
import InviteEmployees from './Screens/AuthScreens/InviteEmployees/InviteEmployees'
import EmployeesScreen from './Screens/AuthScreens/EmployeesScreen'
import Employees from './Screens/AuthScreens/Employees/Employees'
import AppSettings from './Screens/AppSettings/AppSettings'
import Register from './Screens/AuthScreens/Register/Register'
import SettingsScreen from './Screens/Settings/SettingsScreen'
import OrderSettings from './Screens/Settings/OrderSettings'
import RecoverPassOne from './Screens/AuthScreens/RecoverPassOne'
import RecoverPassThree from './Screens/AuthScreens/RecoverPassThree'

import HomeScreen from './Screens/HomeScreen/HomeScreen'
import ContactInfosScreen from './Screens/ContactInfosScreen/ContactInfosScreen'
import UsersScreen from './Screens/UsersScreen/UsersScreen'
import RestaurantScreen from './Screens/RestaurantScreen/RestaurantScreen/RestaurantScreen'
import AddressScreen from './Screens/AddressScreen/AddressScreen'
import Areas from './Screens/AddressScreen/Areas'
import Districts from './Screens/AddressScreen/Districts'
import Divisions from './Screens/AddressScreen/Divisions'
import Customers from './Screens/UsersScreen/Customers/Customers'
import AddressesFromCustomer from './Screens/UsersScreen/Customers/AddressesFromCustomer/AddressesFromCustomer'
import RestaurantAreas from './Screens/RestaurantScreen/RestaurantAreas'
// import Categories from './Screens/ItemsScreen/Categories'
// import DeliveryFees from './Screens/OrderScreen/DeliveryFees'
// import Discount from './Screens/OrderScreen/Discount'
// import Vats from './Screens/OrderScreen/Vats'
import OrderScreen from './Screens/OrderScreen/OrderScreen'
import ItemsScreen from './Screens/ItemsScreen/ItemsScreen'
import Items from './Screens/ItemsScreen/Items/Items'
// import FeaturedItems from './Screens/ItemsScreen/FeaturedItems'
import RestaurantInfoScreen from './Screens/RestaurantScreen/RestaurantInfoScreen'
// import EmeraldAreas from './Screens/Settings/EmeraldAreas'
import ComboScreen from './Screens/ComboScreen/ComboScreen'
import ComboItems from './Screens/ComboScreen/ComboItems/ComboItems'

import AllManagers from './Screens/AuthScreens/AllManagers/AllManagers'
import AllOwners from './Screens/AuthScreens/AllOwners/AllOwners'
import OrderScreens from './Screens/OrderScreens/OrderScreens'
import OrdersFromStatus from './Screens/OrderScreens/OrdersFromStatus/OrdersFromStatus'
import OrderItemsFromOrders from './Screens/OrderScreens/OrderItemsFromOrders'
import useLevels from '../Utilities/Permissions/useLevels'
import DashboardScreen from './Screens/DashboardScreen/DashboardScreen'
import PerformanceReports from './Screens/PerformanceReports/PerformanceReports';
import DailyReports from './Screens/PerformanceReports/DailyReports/DailyReports';

import { useContext } from 'react'
import MonthlyRoports from './Screens/PerformanceReports/MonthlyRoports/MonthlyRoports'
import TimeRangeReports from './Screens/PerformanceReports/TimeRangeReports/TimeRangeReports'
import HeroSliders from './Screens/HomeScreen/Sliders/HeroSliders'
// import FeaturedCombos from './Screens/ComboScreen/FeaturedCombos'
import DeliveryFees from './Screens/OrderScreen/DeliveryFees/DeliveryFees'
import Discount from './Screens/OrderScreen/Discount/Discount'
import Vats from './Screens/OrderScreen/Vats/Vats'
import Categories from './Screens/ItemsScreen/Categories/Categories'
import Branch from './Screens/RestaurantScreen/Branch/Branch'
import SocialMediaScreen from './Screens/SocialMediaScreen/SocialMediaScreen'
import BranchScreen from './Screens/RestaurantScreen/Branch/BranchScreen/BranchScreen'
import EmeraldAreas from './Screens/Settings/EmeraldAreas/EmeraldAreas'
import BranchArea from './Screens/RestaurantScreen/Branch/BranchScreen/BranchArea/BranchArea'
import BranchItems from './Screens/RestaurantScreen/Branch/BranchScreen/BranchItems/BranchItems'
import Feedback from './Screens/Settings/Feedback/Feedback'
import FeaturedItems from './Screens/ItemsScreen/FeaturedItems/FeaturedItems'
import FeaturedCombos from './Screens/ComboScreen/FeaturedCombos/FeaturedCombos'
import BranchCategory from './Screens/RestaurantScreen/Branch/BranchCategory/BranchCategory'
import AuthContext from '../Context/AuthContext'
import AppContext from '../Context/AppContext'
import Modal from './Partials/Elements/Modal/Modal'
import PendingOrders from './Screens/PendingOrders/PendingOrders'
import EmeraldZones from './Screens/Settings/EmraldZones/EmeraldZones'
import CustomerOrders from './Screens/UsersScreen/Customers/CustomerOrders/CustomerOrders'



function App() {
  const { logout, employee } = useContext(AuthContext)

  const { setNewOrder, newOrder, myBranch} = useContext(AppContext)

  const { superAdmin, owner, branchManager } = useLevels()

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response.status === 401) {
        logout()
      }
      return Promise.reject(err);
    }
  );


  function handleClose() {
    setNewOrder(false); 
}

console.log(myBranch)
  return (
    <section>
      <BrowserRouter>
        <NavBar />
        <main>
          {employee && <SideBar />}
          <section className='screen-container'>

            <Routes>

              <Route path='/login' element={!employee ? <Login /> : <Navigate to={'/'} />} />
              <Route path='/register/:token' element={!employee ? <Register /> : <Navigate to={'/'} />} />
              <Route path="/recover-pass-one" element={<RecoverPassOne />} />
              <Route path="/resetEmployeePassword/:token" element={<RecoverPassThree />} />

              <Route path='/employeeInvites' element={(employee && (owner || superAdmin)) ? <InviteEmployees /> : <Navigate to={'/login'} />} />
              <Route path='/employeesScreen' element={employee ? <EmployeesScreen /> : <Navigate to={'/login'} />} />
              <Route path='/employees' element={(employee && (owner || superAdmin)) ? <Employees /> : <Navigate to={'/login'} />} />
              <Route path='/allManagers' element={(employee && (owner || superAdmin)) ? <AllManagers /> : <Navigate to={'/login'} />} />
              <Route path='/allOwners' element={(employee && superAdmin) ? <AllOwners /> : <Navigate to={'/login'} />} />
              <Route path='/customers' element={employee ? <Customers /> : <Navigate to={'/login'} />} />

              <Route path='/reports' element={(employee && (owner || superAdmin || branchManager)) ? <PerformanceReports /> : <Navigate to={'/login'} />} />
              <Route path='/dailyReports' element={(employee && (owner || superAdmin || branchManager)) ? <DailyReports /> : <Navigate to={'/login'} />} />
              <Route path='/monthlyRoports' element={(employee && (owner || superAdmin || branchManager)) ? <MonthlyRoports /> : <Navigate to={'/login'} />} />
              <Route path='/timeRangeReports' element={(employee && (owner || superAdmin || branchManager)) ? <TimeRangeReports /> : <Navigate to={'/login'} />} />

              <Route path='/dashboard' element={employee ? <DashboardScreen /> : <Navigate to={'/login'} />} />
              {/* <Route path='/' element={(employee && (owner || superAdmin || branchManager)) ? <Dashboard /> : <Navigate to={'/login'} />} /> */}
              <Route path='/' element={
                employee && (owner || superAdmin || branchManager) ? (
                  (branchManager && <DashboardScreen />) ||
                  ((owner || superAdmin) && <Dashboard />)
                ) : (
                  <Navigate to='/login' />
                )
              } />
              <Route path='/homeScreen' element={(employee && (owner || superAdmin || branchManager)) ? <HomeScreen /> : <Navigate to={'/login'} />} />
              <Route path='/heroSliders' element={(employee && (owner || superAdmin || branchManager)) ? <HeroSliders /> : <Navigate to={'/login'} />} />
              
              <Route path='/restaurants' element={(employee && (owner || branchManager || superAdmin)) ? <RestaurantScreen /> : <Navigate to={'/login'} />} />
              <Route path='/restaurantInfoScreen/:parentID' element={(employee && (owner || branchManager || superAdmin)) ? <RestaurantInfoScreen /> : <Navigate to={'/login'} />} />
              <Route path='/restaurantAreas/:parentID' element={(employee && (owner || branchManager || superAdmin)) ? <RestaurantAreas /> : <Navigate to={'/login'} />} />

              <Route path='/itemsScreen' element={(employee && (owner || superAdmin || branchManager)) ? <ItemsScreen /> : <Navigate to={'/login'} />} />
              <Route path='/categories' element={(employee && (owner || superAdmin || branchManager)) ? <Categories /> : <Navigate to={'/login'} />} />
              <Route path='/items/:parentID' element={(employee && (owner || superAdmin || branchManager)) ? <Items /> : <Navigate to={'/login'} />} />
              {/* <Route path='/categories/:parentID' element={(employee && (owner || superAdmin)) ? <Categories /> : <Navigate to={'/login'} />} /> */}
              <Route path='/featuredItems' element={(employee && (owner || superAdmin)) ? <FeaturedItems /> : <Navigate to={'/login'} />} />
              <Route path='/combo/:parentID' element={(employee && (owner || superAdmin)) ? <ComboScreen /> : <Navigate to={'/login'} />} />
              <Route path='/comboItems/:parentID' element={(employee && (owner || superAdmin)) ? <ComboItems /> : <Navigate to={'/login'} />} />
              <Route path='/FeaturedCombos' element={(employee && (owner || superAdmin)) ? <FeaturedCombos /> : <Navigate to={'/login'} />} />

              <Route path={'/orderScreens'} element={(employee && (owner || superAdmin || branchManager)) ? <OrderScreens /> : <Navigate to={'/login'} />} />
              <Route path={'/ordersFromStatus/:status'} element={(employee && (owner || superAdmin || branchManager)) ? <OrdersFromStatus /> : <Navigate to={'/login'} />} />
              <Route path={'/order_items_from_order/:orderID'} element={(employee && (owner || superAdmin || branchManager)) ? <OrderItemsFromOrders /> : <Navigate to={'/login'} />} />

              <Route path='/fees' element={(employee && (owner || superAdmin || branchManager)) ? <OrderScreen /> : <Navigate to={'/login'} />} />
              <Route path='/deliveryFees' element={(employee && (owner || superAdmin || branchManager)) ? <DeliveryFees /> : <Navigate to={'/login'} />} />
              <Route path='/discount' element={(employee && (owner || superAdmin || branchManager)) ? <Discount /> : <Navigate to={'/login'} />} />
              <Route path='/vats' element={(employee && (owner || superAdmin || branchManager)) ? <Vats /> : <Navigate to={'/login'} />} />


              <Route path='/addressScreen' element={(employee && (owner || superAdmin || branchManager)) ? <AddressScreen /> : <Navigate to={'/login'} />} />
              <Route path='/addressesFromCustomer/:customerID' element={(employee && (owner || superAdmin || branchManager)) ? <AddressesFromCustomer /> : <Navigate to={'/login'} />} />
              <Route path='/customerOrders/:customerID' element={(employee && (owner || superAdmin || branchManager)) ? <CustomerOrders /> : <Navigate to={'/login'} />} />

              <Route path='/areas/:parentID' element={employee ? <Areas /> : <Navigate to={'/login'} />} />
              <Route path='/districts/:parentID' element={employee ? <Districts /> : <Navigate to={'/login'} />} />
              <Route path='/divisions' element={employee ? <Divisions /> : <Navigate to={'/login'} />} />

              <Route path='/settingsScreen' element={employee ? <SettingsScreen /> : <Navigate to={'/login'} />} />
              <Route path='/appSettings' element={employee ? <AppSettings /> : <Navigate to={'/login'} />} />
              <Route path='/emeraldZones' element={(employee && (owner || superAdmin)) ? <EmeraldZones /> : <Navigate to={'/login'} />} />
              <Route path='/emeraldAreas/:zoneID' element={(employee && (owner || superAdmin)) ? <EmeraldAreas /> : <Navigate to={'/login'} />} />
              <Route path='/branch/:parentID' element={(employee && (owner || branchManager|| superAdmin)) ? <Branch /> : <Navigate to={'/login'} />} />
              <Route path='/feedback' element={(employee && (owner || superAdmin)) ? <Feedback /> : <Navigate to={'/login'} />} />

              <Route path='/branchScreen/:parentID' element={(employee && (owner || branchManager|| superAdmin)) ? <BranchScreen /> : <Navigate to={'/login'} />} />
              <Route path='/branchArea/:branchID' element={(employee && (owner || branchManager|| superAdmin)) ? <BranchArea /> : <Navigate to={'/login'} />} />
              <Route path='/branchItems/:branchID/:categoryName/:categoryID' element={(employee && (owner || branchManager || superAdmin)) ? <BranchItems /> : <Navigate to={'/login'} />} />
              <Route path='/branchCategory/:branchID' element={(employee && (owner || branchManager || superAdmin)) ? <BranchCategory /> : <Navigate to={'/login'} />} />


              {/* <Route path='/socialLinks/:parentID' element={(employee && (owner || superAdmin)) ? <SocialLinks /> : <Navigate to={'/login'} />} /> */}
              <Route path='/socialLinks/:parentID' element={(employee && (owner || branchManager || superAdmin)) ? <SocialMediaScreen /> : <Navigate to={'/login'} />} />
              <Route path='/contactInfos' element={(employee && (owner || superAdmin)) ? <ContactInfosScreen /> : <Navigate to={'/login'} />} />
              <Route path='/usersScreen' element={(employee && (owner || superAdmin)) ? <UsersScreen /> : <Navigate to={'/login'} />} />
              <Route path='/orderSettings' element={(employee && (owner || superAdmin || branchManager)) ? <OrderSettings /> : <Navigate to={'/login'} />} />

            </Routes>
            {newOrder &&
              <Modal
                handleClose={handleClose}
              >
                <PendingOrders />
              </Modal>
            }

          </section>
        </main>
      </BrowserRouter>

    </section>
  )
}

export default App
