import { useState } from "react"
import axios from "axios"
import Form from "../../../Partials/Layouts/Forms/Form"
import { HERO_SLIDERS_API } from "../../../../Utilities/APIs/APIs"
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import useAxiosInstance from './../../../../Utilities/Hooks/useAxiosInstance';


function CreateHeroSlider({

    employee, setShowCreateForm, setShowModal, triggerFetch

}) {

    const [image, setImage] = useState('')
    const [mobileImage, setMobileImage] = useState('')
    const [precedence, setPrecedence] = useState('')
    const [link, setLink] = useState('')
    const axiosInstance = useAxiosInstance()

    async function handleSubmit(e) {

        e.preventDefault()

        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }
        const itemData = new FormData()

        itemData.append('image', image)
        itemData.append('mobileImage', mobileImage)
        itemData.append('precedence', precedence)
        itemData.append('link', link)

        const response = await axiosInstance.post(HERO_SLIDERS_API, itemData, config)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>

            <ImageInput
                fieldId='1'
                state={image}
                setState={setImage}
                allowCreateImage
            >
                Upload Card Image
            </ImageInput>
            <ImageInput
                fieldId='2'
                state={mobileImage}
                setState={setMobileImage}
                allowCreateImage
            >
                Upload Card Mobile Image
            </ImageInput>
            <NumberInput
                label={`Precedence`}
                value={precedence}
                placeholder={`Enter Precedence`}
                setState={setPrecedence}
            />
            <ShortTextInput
                label={`Link`}
                value={link}
                placeholder={`Enter Link`}
                setState={setLink}
            />


            {/* <ImageInput fieldId='1' state={image} setState={setImage} allowCreateImage>Upload Card Image</ImageInput> */}

            <FormSubmitButton text='Create Hero Slider' />
        </Form>
    )
}

export default CreateHeroSlider