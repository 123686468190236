import { useState, useEffect } from "react"
import axios from "axios"
import { IMAGE_URL, MANAGE_ITEM_API } from "../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";


function ViewItem({

    //common props
    targetID, employee

}) {

    const [item, setItem] = useState(null)
    const axiosInstance = useAxiosInstance();


    useEffect(() => {
        async function fetchAndSetItem() {
            const { data } = await axiosInstance.get(MANAGE_ITEM_API + 'getSingleItem/' + targetID)
            setItem(data);
        }
        fetchAndSetItem()
    }, [targetID, axiosInstance])

    return (

        <div className="crud_view_content">

            {item &&
                <>
                    <h1>Name</h1>
                    <p>{item.name}</p>
                    <h1>Description</h1>
                    <p>{item.description}</p>
                    <h1>Image</h1>
                    <img src={IMAGE_URL + item.image} alt="" />
                    <h1>Price</h1>
                    <p>{item.price}</p>
                    {/* <h1>Is Available?</h1>
                    <p>{item?.isAvailable === true ? 'TRUE' : 'FALSE'}</p> */}
                    <h1>Is Active?</h1>
                    <p>{item.isActive === true ? 'TRUE' : 'FALSE'}</p>
                    <h1>Is Combo</h1>
                    <p>{item.isCombo === true ? 'TRUE' : 'FALSE'}</p>

                </>
            }
        </div>
    )
}

export default ViewItem