import { useState } from "react"
import Form from "../../../Partials/Layouts/Forms/Form"
import { HERO_SLIDERS_API } from "../../../../Utilities/APIs/APIs"
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"
import { useEffect } from "react"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"


function UpdateHeroSlider({

    targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {

    const [image, setImage] = useState('')
    const [mobileImage, setMobileImage] = useState('')
    const [precedence, setPrecedence] = useState('')
    const [link, setLink] = useState('')

    const axiosInstance = useAxiosInstance()
    useEffect(() => {

        async function fetchAndSetSliderInfo() {

            const { data } = await axiosInstance.get(HERO_SLIDERS_API + 'getSingleHeroSlider/' + targetID)
            setImage(data.image);
            setMobileImage(data?.mobileImage);
            setPrecedence(data?.precedence);
            setLink(data.link);
        }
        fetchAndSetSliderInfo()
    }, [targetID, axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('image', image)
        itemData.append('mobileImage', mobileImage)
        itemData.append('precedence', precedence)
        itemData.append('link', link)

        const response = await axiosInstance.patch(HERO_SLIDERS_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>

            <ImageInput
                fieldId='1'
                state={image}
                setState={setImage}
                allowUpdateImage
            >
                Upload Card Image
            </ImageInput>
            <ImageInput
                fieldId='2'
                state={mobileImage}
                setState={setMobileImage}
                allowUpdateImage
            >
                Upload Card Mobile Image
            </ImageInput>
            <NumberInput
                label={`Precedence`}
                value={precedence}
                placeholder={`Enter Precedence`}
                setState={setPrecedence}
            />
             <ShortTextInput
                label={`Link`}
                value={link}
                placeholder={`Enter Link`}
                setState={setLink}
            />


            <FormSubmitButton text='Update Hero Slider' />
        </Form>
    )
}

export default UpdateHeroSlider