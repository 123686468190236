import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { BRANCH_AREAS_API, EMERALD_AREAS_API } from "../../../../../../Utilities/APIs/APIs";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import SelectInput from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";


function UpdateBranchArea({ targetID, branchID, setShowUpdateForm, setShowModal, triggerFetch}) {

    const [branches, setBranches] = useState(null)
    const [emeraldArea, setEmeraldArea] = useState('')
    
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetRestaurant() {
            const { data } = await axiosInstance.get(BRANCH_AREAS_API + 'getSingleBranchArea/' + targetID)
            console.log("🚀sssssssss", data)
            
            // setBranches(data?.emeraldArea?.name);
          
       
        }
        fetchAndSetRestaurant()

        async function fetchAndSetCategories() {
           
            const { data } = await axiosInstance.get(EMERALD_AREAS_API)
            setBranches(data)
        }
        fetchAndSetCategories()
    }, [axiosInstance, targetID])

    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = new FormData()

        itemData.append('emeraldArea', emeraldArea)
        itemData.append('branch', branchID)
        const response = await axiosInstance.patch(BRANCH_AREAS_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
        <SelectInput value={emeraldArea} setState={setEmeraldArea}>
            <SelectOption optionValue={''} optionText={`Select Emerald Area`} />
            {branches && branches.map(cat => (
                <SelectOption key={cat._id} optionValue={cat._id} optionText={cat.name} />
            ))}
        </SelectInput>
        <FormSubmitButton text='Update Branch Area' />
    </Form>
    )
}

export default UpdateBranchArea
