import { useEffect, useState } from "react";
import slugify from 'react-slugify';
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { MANAGE_BRANCH_API } from "../../../../Utilities/APIs/APIs";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../Partials/Layouts/Forms/Form";
import LongTextInput from "../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";

function UpdateBranch({
  targetID,
  restaurantId,
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
}) {
  const [name, setName] = useState("");
  const [homeCardImage, setHomeCardImage] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [coverImage2, setCoverImage2] = useState("");
  const [description, setDescription] = useState("");
  const [mobile, setMobile] = useState("");
  const [logo, setLogo] = useState("");
  const [cuisine, setCuisine] = useState("");

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetRestaurant() {
      const { data } = await axiosInstance.get(
        MANAGE_BRANCH_API + "singleBranchData/" + targetID
      );

      setName(data?.branch?.name);
      setCuisine(data?.branch?.cuisine);
      setDescription(data?.branch?.description);
      setHomeCardImage(data?.branch?.homeCardImage);
      setMobile(data?.branch?.mobile);
      setCoverImage(data?.branch?.coverImage);
      setCoverImage2(data?.branch?.coverImage2);
      setLogo(data?.branch?.logo);
    }
    fetchAndSetRestaurant();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("restaurant", restaurantId);
    itemData.append("name", name);
    itemData.append("slug", slugify(name));
    itemData.append("cuisine", cuisine);
    itemData.append("description", description);
    itemData.append("mobile", mobile);
    itemData.append("homeCardImage", homeCardImage);
    itemData.append("coverImage", coverImage);
    itemData.append("coverImage2", coverImage2);
    itemData.append("logo", logo);

    const response = await axiosInstance.patch(
      MANAGE_BRANCH_API + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);

      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />
      <ShortTextInput
        label={`Slug`}
        value={slugify(name)}
        placeholder={`Enter Slug`}

      />
      <LongTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />
      <ShortTextInput
        label={`Cuisine`}
        value={cuisine}
        placeholder={`Enter Cuisine`}
        setState={setCuisine}
      />
      <NumberInput
        label={"Mobile Number"}
        value={mobile}
        placeholder={"Enter Mobile Number"}
        setState={setMobile}
      />
      <ImageInput
        fieldId="1"
        state={homeCardImage}
        setState={setHomeCardImage}
        allowUpdateImage
      >
        Upload Card Image
      </ImageInput>
      <ImageInput
        fieldId="2"
        state={coverImage}
        setState={setCoverImage}
        allowUpdateImage
      >
        Upload Cover Image
      </ImageInput>
      <ImageInput
        fieldId="3"
        state={coverImage2}
        setState={setCoverImage2}
        allowUpdateImage
      >
        Upload Cover Image2
      </ImageInput>
      <ImageInput fieldId="4" state={logo} setState={setLogo} allowUpdateImage>
        Upload Logo
      </ImageInput>
      <FormSubmitButton text="Update Restaurant" />
    </Form>
  );
}

export default UpdateBranch;
