import ReactTooltip from "react-tooltip";
import "./CRUDButton.css";

function CRUDButton({
  children,
  handleClick,
  deleteButton,
  dataTip,
  dataFor,
  ...rest
}) {
  return (
    <>
      <button
        data-tip={dataTip}
        data-for={dataFor}
        className={deleteButton ? "delete_btn" : ""}
        onClick={handleClick}
        {...rest}
      >
        {children}
      </button>
      <ReactTooltip id={dataFor} place="top" effect="solid" />
    </>
  );
}

export default CRUDButton;
