import { useState, useEffect } from "react"
import axios from "axios"
import { HERO_SLIDERS_API, IMAGE_URL } from "../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"

function ViewHeroSlider({

    //common props
    targetID, employee

}) {

    const [slider, setSlider] = useState(null)
    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetSliderInfo() {

            const { data } = await axiosInstance.get(HERO_SLIDERS_API + "getSingleHeroSlider/" + targetID)
            setSlider(data);
        }
        fetchAndSetSliderInfo()
    }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">

            {slider &&
                <>
                   
                    <h1>Image</h1>
                    <img src={IMAGE_URL + slider.image} alt="" />
                    <h1>Mobile Image</h1>
                    <img src={IMAGE_URL + slider.mobileImage} alt="" />
                    <h1>Precedence</h1>
                    <p>{slider.precedence}</p>
                    <h1>Link</h1>
                    <p>{slider.link}</p>

                </>
            }
        </div>
    )
}

export default ViewHeroSlider