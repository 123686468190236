import React, { useContext, useEffect, useState } from 'react'
import { SOCIAL_LINKS_API } from '../../../Utilities/APIs/APIs'
import AuthContext from '../../../Context/AuthContext';
import useAxiosInstance from '../../../Utilities/Hooks/useAxiosInstance';
import CreateButton from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton';
import CRUDTable from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import ViewButton from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton';
import ShortTextCell from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import CRUDTableRow from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow';
import EditButton from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton';
import Modal from '../../Partials/Elements/Modal/Modal';
import CreateSocialMediaScreen from './CreateSocialMediaScreen';
import UpdateSocialMediaScreen from './UpdateSocialMediaScreen';
import ViewSocialMediaScreen from './ViewSocialMediaScreen';
import CRUDth from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import { useParams } from 'react-router-dom';
import DeleteButton from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton';
import DeleteItem from '../../Partials/Layouts/DeleteItem/DeleteItem';
import BackButton from '../../Partials/Elements/BackButton/BackButton';

function SocialMediaScreen() {
    const { parentID } = useParams()


    const [socialMedias, setSocialMedias] = useState(null);
    const [restaurants, setRestaurants] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [targetID, setTargetID] = useState(null);
    const [showViewSection, setShowViewSection] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showDeleteSection, setShowDeleteSection] = useState(false);
    const [toggleFetch, setToggleFetch] = useState(false);
    const { employee } = useContext(AuthContext);
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetSocialMediaScreen() {
            const { data } = await axiosInstance.get(SOCIAL_LINKS_API + 'getSocialLinkForRestaqurant/' + parentID);
            setSocialMedias(data);
        }
        fetchAndSetSocialMediaScreen();
    }, [toggleFetch, axiosInstance, parentID]);

    function handleClose() {
        setShowModal(false);
        setShowCreateForm(false);
        setShowViewSection(false);
        setShowUpdateForm(false);
        setShowDeleteSection(false);
    }

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }
    return (
        <>
            <div className="crud_holder">
                <div className="body_header">
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                        <BackButton />
                        <h1 className="heading">{`Social Media (${socialMedias?.length} in total)`}</h1>
                    </div>

                    <CreateButton
                        screenTopicSingular="Social Media"
                        setShowModal={setShowModal}
                        setShowCreateForm={setShowCreateForm}
                    />
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th="Name" />
                        <CRUDth th="Visibility" />
                        <CRUDth th="Link" />
                        <CRUDth th="Actions" />
                    </CRUDTableHeader>
                    <tbody>
                        {socialMedias?.map((socialMedia) => (
                            <CRUDTableRow key={socialMedia._id}>
                                <ShortTextCell text={socialMedia.name} />
                                <ShortTextCell text={socialMedia.visibility ? "true" : "false"} />
                                <ShortTextCell text={socialMedia.link} />

                                <td className="action_button_cell">
                                    <ViewButton
                                        setShowModal={setShowModal}
                                        setShowViewSection={setShowViewSection}
                                        targetID={socialMedia._id}
                                        setTargetID={setTargetID}
                                    />
                                    <EditButton
                                        setShowModal={setShowModal}
                                        setShowUpdateForm={setShowUpdateForm}
                                        targetID={socialMedia._id}
                                        setTargetID={setTargetID}
                                    />
                                    {/* <DeleteButton
                                        setShowModal={setShowModal}
                                        setShowDeleteSection={setShowDeleteSection}
                                        targetID={socialMedia._id}
                                        setTargetID={setTargetID}
                                    /> */}
                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal && (
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showCreateForm && `Create Social Link`) ||
                        (showUpdateForm && `Update Social Link`) ||
                        (showDeleteSection && `Delete Social Link`)
                    }
                >
                    {showCreateForm && (
                        <CreateSocialMediaScreen
                            employee={employee}
                            setShowCreateForm={setShowCreateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                            restaurantID={parentID}
                        />
                    )}
                    {showUpdateForm && (
                        <UpdateSocialMediaScreen
                            employee={employee}
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                            restaurantID={parentID}
                        />
                    )}

                    {showDeleteSection && (
                        <DeleteItem
                            api={SOCIAL_LINKS_API}
                            targetID={targetID}
                            employee={employee}
                            setShowModal={setShowModal}
                            setShowDeleteSection={setShowDeleteSection}
                            hasName
                            triggerFetch={triggerFetch}
                        />
                    )}
                </Modal>
            )}

            {showModal && showViewSection && (
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={showViewSection && `View Social Link`}
                >
                    <ViewSocialMediaScreen targetID={targetID} employee={employee} />
                </Modal>
            )}
        </>
    )
}

export default SocialMediaScreen