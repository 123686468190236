import { useState, useEffect } from "react"
import { IMAGE_URL, MANAGR_RESTAURANTS_API,  } from "../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"


function ViewRestaurant({

    //common props
    targetID, employee

}) {

    const [restaurant, setRestaurant] = useState(null)
    const axiosInstance = useAxiosInstance();
    useEffect(() => {
        async function fetchAndSetRestaurant() {
            const { data } = await axiosInstance.get(MANAGR_RESTAURANTS_API + 'getSingleRestaurant/' + targetID)
            setRestaurant(data);
        }
        fetchAndSetRestaurant()
    }, [targetID, axiosInstance])

    return (

        <div className="crud_view_content">

            {restaurant &&
                <div>
                    <h1>Name</h1>
                    <p>{restaurant.name}</p>
                    <h1>Cuisine</h1>
                    <p>{restaurant.cuisine}</p>
                    
                    {/* <h1>Description</h1>
                    <p>{restaurant.description}</p>
                    <h1>Home Card Image</h1>
                    <img src={IMAGE_URL + restaurant.homeCardImage} alt="" />
                    <h1>Cover Image</h1>
                    <img src={IMAGE_URL + restaurant.coverImage} alt="" />
                    <h1>Logo</h1>
                    <img src={IMAGE_URL + restaurant.logo} alt="" />
                    <h1>About Us Image1</h1>
                    <img src={IMAGE_URL + restaurant.aboutUsImage1} alt="" />
                    <h1>About Us Image2</h1>
                    <img src={IMAGE_URL + restaurant.aboutUsImage2} alt="" />
                    <h1>Address</h1>
                    <p>{restaurant.address}</p>
                    <h1>Phone</h1>
                    <p>{restaurant.phone}</p>
                    <h1>Email</h1>
                    <p>{restaurant.email}</p> */}

                </div>
            }
        </div>
    )
}

export default ViewRestaurant