import {  useState } from "react"
import axios from "axios"
import { COMBO_ITEMS_API } from "../../../../Utilities/APIs/APIs"
import Form from "../../../Partials/Layouts/Forms/Form";

import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function CreateComboItem({

    restaurantItems, combo, employee, setShowCreateForm, setShowModal, triggerFetch

}) {

  const [item, setItem]= useState(null)
  const axiosInstance = useAxiosInstance()

    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = new FormData()

      
        itemData.append('item', item)
        itemData.append('combo', combo)



        const response = await axiosInstance.post(COMBO_ITEMS_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <SelectInput value={item} setState={setItem}>
                <SelectOption optionValue={''} optionText={`Select Category`} />
                {restaurantItems && restaurantItems.map(restaurantItem => (
                    <SelectOption key={restaurantItem._id} optionValue={restaurantItem._id} optionText={restaurantItem.name} />
                ))}
            </SelectInput>
         


            <FormSubmitButton text='Create Combo Item' />
        </Form>
    )
}

export default CreateComboItem