import React from 'react'
import PcSSCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/SSCRUD/PcSSCRUD'
import { EMERALD_AREAS_API, RESTAURANTS_API, RESTAURANT_AREAS_API } from '../../../Utilities/APIs/APIs'

function RestaurantAreas() {
  return (
    <PcSSCRUD

    // parentChild props
    parentDBField='restaurant'

    // common props
    api={RESTAURANT_AREAS_API}
    screenTopicSingular='Restuarant Area'
    screenTopicPlural='Restuarant Areas'

    // crud header props
    showTotalInHeading
    allowCreate
    // allowDelete

    // select props
    selectApi={RESTAURANTS_API}
    selectType='parent-child'
    selectDBField='restaurant'
    selectName='Restaurant'

    // secondSelect props
    secondSelectApi={EMERALD_AREAS_API}
    secondSelectName='Area'
    secondSelectDBField='emeraldArea'
/>
  )
}

export default RestaurantAreas