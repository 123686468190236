import { useEffect, useState } from "react";
import {
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiRepeatFill,
  RiEBike2Fill,
  RiRestaurant2Fill,
} from "react-icons/ri";
// import { useSelector } from "react-redux";
import { ORDERS_API } from "../../../Utilities/APIs/APIs";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import ScreenHolder from "../../Partials/Layouts/ScreenHolder/ScreenHolder";
import AppContext from "../../../Context/AppContext";
import { useContext } from "react";

import "./OrderScreens.css";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function OrderScreens() {
  const [orders, setOrders] = useState(null);
  const { myBranch } = useContext(AppContext);
  const branchId = myBranch?._id;
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    if (branchId !== null && branchId !== undefined) {
      async function fetchAndSetItems() {
        // const { data } = await axios.get(ORDERS_API + 'getAllOrders', config)
        const { data } = await axiosInstance.get(
          "/customers/orders/getAllOrdersFromMyBranch"
        );

        setOrders(data);
      }
      fetchAndSetItems();
    }
    if (!branchId) {
      async function fetchAndSetItems() {
        const { data } = await axiosInstance.get("/customers/orders");
        setOrders(data);
      }
      fetchAndSetItems();
    }
  }, [axiosInstance, branchId]);



  
  return (
    <ScreenHolder>
      <NavCardList numOfCards="five">
        <NavCard
          showBadge
          number={orders?.filter((i) => i.orderStatus === "processing")?.length}
          cardName={`Processing`}
          navCardLink={"/ordersFromStatus/processing"}
        >
          <RiRepeatFill />
        </NavCard>
        <NavCard
          showBadge
          number={
            orders?.filter((i) => i.orderStatus === "toBeDelivered")?.length
          }
          cardName={`In Kitchen`}
          navCardLink={"/ordersFromStatus/toBeDelivered"}
        >
          <RiRestaurant2Fill />
        </NavCard>
        <NavCard
          showBadge
          number={orders?.filter((i) => i.orderStatus === "shipped")?.length}
          cardName={`Delivering`}
          navCardLink={"/ordersFromStatus/shipped"}
        >
          <RiEBike2Fill />
        </NavCard>
        <NavCard
          cardName={`Completed(${
            orders?.filter((i) => i.orderStatus === "completed")?.length
          })`}
          navCardLink={"/ordersFromStatus/completed"}
        >
          <RiCheckboxCircleFill />
        </NavCard>
        <NavCard
          cardName={`Canceled(${
            orders?.filter((i) => i.orderStatus === "canceled")?.length
          })`}
          navCardLink={"/ordersFromStatus/canceled"}
        >
          <RiCloseCircleFill />
        </NavCard>
      </NavCardList>
    </ScreenHolder>
  );
}

export default OrderScreens;
