import React from 'react'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import { RiFacebookFill, RiImage2Line, RiMapPinLine } from 'react-icons/ri'
import { useParams } from 'react-router-dom'
import BackButton from '../../Partials/Elements/BackButton/BackButton'

function RestaurantInfoScreen() {
    const { parentID } = useParams()
    return (


        <div style={{display:"flex",flexDirection:"column",paddingTop:"20px",gap:"10px",height:"89dvh"}}>
            <div style={{paddingBottom:"20px"}}>
                <BackButton />
            </div>
            <NavCardList numOfCards={'four'}>
                {/* <NavCard cardName={'Categories'} navCardLink={'/categories/' + parentID }><RiCollageLine /></NavCard> */}
                <NavCard cardName={'Items'} navCardLink={'/items/' + parentID} ><RiImage2Line /></NavCard>
                <NavCard cardName={'Social Links'} navCardLink={'/socialLinks/' + parentID} ><RiFacebookFill /></NavCard>
                <NavCard cardName={'Branch'} navCardLink={'/branch/' + parentID} ><RiMapPinLine /></NavCard>
                {/* <NavCard cardName={'Restaurant Area'} navCardLink={'/restaurantAreas/' + parentID} ><RiMapPinLine /></NavCard>
            <NavCard cardName={'Combo'} navCardLink={'/combo/' + parentID} ><RiGridLine /></NavCard> */}
            </NavCardList>
        </div>

    )
}

export default RestaurantInfoScreen