import { useEffect, useState } from "react"
import Switch from "react-switch";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { BRANCH_AREAS_API, BRANCH_ITMS_API, CATEGORIES_API, MANAGE_ITEM_API,  } from "../../../../../../Utilities/APIs/APIs";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import SelectInput from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";


function CreateBranchItems({ branchID, setShowCreateForm, setShowModal, triggerFetch,categoryID, restaurantID}) {
    
    const [items, setItems] = useState(null)
    const [item, setitem] = useState('')
    const [precedence,setPrecedence] = useState("")

    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetItem() {
            // const { data } = await axiosInstance.get(`${MANAGE_ITEM_API}?category=${categoryID}`)
            const { data } = await axiosInstance.get(`items/controlItems/manageItem/getNotAddedItems?category=${categoryID}&restaurant=${restaurantID}&branch=${branchID}`)
            setItems(data)
        }
        fetchAndSetItem()
    }, [axiosInstance,categoryID])

    // useEffect(() => {
    //     async function fetchAndSetCategories() {
           
    //         const { data } = await axiosInstance.get(CATEGORIES_API)
    //         setCategories(data)
    //     }

    //     fetchAndSetCategories()
    // }, [axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = new FormData()

        itemData.append('item', item)
        itemData.append('branch', branchID)
        itemData.append("precedence",precedence)
        itemData.append('category', categoryID)

        const response = await axiosInstance.post(BRANCH_ITMS_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <SelectInput value={item} setState={setitem}>
                <SelectOption optionValue={''} optionText={`Select Emerald Items`} />
                {items && items.map(cat => (
                    <SelectOption key={cat._id} optionValue={cat._id} optionText={cat.name} />
                ))}
            </SelectInput>

            {/* <SelectInput value={category} setState={setCategory}>
                <SelectOption optionValue={''} optionText={`Select Categories`} />
                {categories && categories.map(cat => (
                    <SelectOption key={cat._id} optionValue={cat._id} optionText={cat.name} />
                ))}
            </SelectInput> */}

            <NumberInput
                label={`Precedence`}
                value={precedence}
                placeholder={`Enter Precedence`}
                setState={setPrecedence}
            />

            <FormSubmitButton text='Create Branch Items' />
        </Form>
    )
}

export default CreateBranchItems