import React, { useState } from "react";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { DELIVERY_FEES_API } from "../../../../Utilities/APIs/APIs";
import SwitchInput from "../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput";

const CreateDeliveryFee = ({
  employee,
  setShowCreateForm,
  setShowModal,
  triggerFetch,
}) => {
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [isApplicable, setIsApplicable] = useState(false);

  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("amount", amount);
    itemData.append("isApplicable", isApplicable);

    const response = await axiosInstance.post(DELIVERY_FEES_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <ShortTextInput
          label={`Name`}
          value={name}
          placeholder={`Enter Name`}
          setState={setName}
        />
        <NumberInput
          label={`Amount`}
          value={amount}
          placeholder={`Enter Amount`}
          setState={setAmount}
        />
        <SwitchInput
          label={"isApplicable"}
          toggleSwitch={() => setIsApplicable((prevState) => !prevState)}
          checked={isApplicable}
        />

        <FormSubmitButton text="Create Delivery Fee" />
      </Form>
    </div>
  );
};

export default CreateDeliveryFee;
