import React, { useEffect } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import { CATEGORIES_API } from '../../../../../Utilities/APIs/APIs';

function FilterCategory({ category, setCategory }) {

    const axiosInstance = useAxiosInstance();
    const [categories, setCategories] = React.useState(null);

    useEffect(() => {
        async function fetchAndSetCategories() {
            const { data } = await axiosInstance.get(
                `${CATEGORIES_API}`
            );
            setCategories(data);
        }
        fetchAndSetCategories();
    }, [axiosInstance]);

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <select  className="filter_select" value={category} onChange={(e) => setCategory(e.target.value)}>
                <option value={""}>Select Category</option>
                {categories?.map((category) => (
                    <option value={category._id}>{category.name}</option>
                ))}
            </select>
        </div>
    )
}

export default FilterCategory