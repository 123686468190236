import { useContext, useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import AuthContext from "../../../../Context/AuthContext";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import {

  EMERALD_ZONES_API,
} from "../../../../Utilities/APIs/APIs";
import CreateEmeraldAreas from "./CreateEmeraldZones";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import ViewEmeraldAreas from "./ViewEmeraldZones";
import UpdateEmeraldAreas from "./UpdateEmeraldZones";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";
import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import FilterSelect from "../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import { useNavigate } from "react-router-dom";
import { FiImage } from "react-icons/fi";

const EmeraldZones = () => {
  const [emeraldAreas, setEmeraldAreas] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();
  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("active");

  const navigate  = useNavigate()
  useEffect(() => {
    async function fetchAndSetRestaurantAreas() {
      const { data } = await axiosInstance.get(
        `${EMERALD_ZONES_API}?filter=${filter}`
      );
      setEmeraldAreas(data);
    }
    fetchAndSetRestaurantAreas();
  }, [toggleFetch, axiosInstance, filter]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Emerald Zones (${emeraldAreas?.length} in total)`}</h1>
          </div>

          <FilterSelect filter={filter} setFilter={setFilter} />
          <CreateButton
            screenTopicSingular="Emerald Zones"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Emerald Zone Name" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {emeraldAreas?.map((zone) => (
              <CRUDTableRow key={zone._id}>
                <ShortTextCell text={zone.name} />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={zone._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={zone._id}
                    setTargetID={setTargetID}
                  />
                  {/* <DeleteButton
                        setShowModal={setShowModal}
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={zone._id}
                        setTargetID={setTargetID}
                    /> */}

                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={zone._id}
                    isArchive={zone?.isActive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />
                  <CRUDButton
                    dataTip=" Emerald Zone"
                    dataFor=" Emerald Zone"
                    handleClick={() =>
                      navigate("/emeraldAreas/" + zone._id)
                    }
                  >
                    <FiImage />
                  </CRUDButton>
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Emerald Zones`) ||
            (showUpdateForm && `Update Emerald Zones`) ||
            (showDeleteSection && `Archive Emerald Zones`)
          }
        >
          {showCreateForm && (
            <CreateEmeraldAreas
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateEmeraldAreas
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            //   <DeleteItem
            //   api={EMERALD_AREAS_API}
            //   targetID={targetID}
            //   employee={employee}
            //   setShowModal={setShowModal}
            //   setShowDeleteSection={setShowDeleteSection}
            //   hasName
            //   triggerFetch={triggerFetch}
            // />

            <ArchiveItem
              api={EMERALD_ZONES_API + "archiveEmeraldZone/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Restaurant Zone`}
        >
          <ViewEmeraldAreas targetID={targetID} employee={employee} />
        </Modal>
      )}
    </>
  );
};

export default EmeraldZones;
