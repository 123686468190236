import React, { useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { EMERALD_AREAS_API, VATS_API } from "../../../../Utilities/APIs/APIs";
import Form from "../../../Partials/Layouts/Forms/Form";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import SwitchInput from "../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";

const CreateEmeraldAreas = ({
  employee,
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  zoneID
}) => {
  const [name, setName] = useState("");

  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("name", name);
    itemData.append("zone", zoneID);

    const response = await axiosInstance.post(EMERALD_AREAS_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <div>
      <Form onSubmit={handleSubmit}>
      <ShortTextInput
          label={`Name`}
          value={name}
          placeholder={`Enter Name`}
          setState={setName}
        />
     
        <FormSubmitButton text="Create Emerald Areas" />
      </Form>
    </div>
  );
};

export default CreateEmeraldAreas;
