import { useState, useEffect } from "react"
import axios from "axios"
import { IMAGE_URL, ITEMS_API } from "../../../../Utilities/APIs/APIs"


function ViewComItem({

    //common props
    targetID, employee

}) {

    const [item, setItem] = useState(null)

    useEffect(() => {
        async function fetchAndSetComboItem() {
            const { data } = await axios.get(ITEMS_API + 'getSingleItem/' + targetID)
            setItem(data);
        }
        fetchAndSetComboItem()
    }, [targetID])

    return (

        <div className="crud_view_content">

            {item &&
                <>
                    <h1>Name</h1>
                    <p>{item.name}</p>
                    <h1>Description</h1>
                    <p>{item.description}</p>
                    <h1>Image</h1>
                    <img src={IMAGE_URL + item.image} alt="" />
                    <h1>Price</h1>
                    <p>{item.price}</p>
                    <h1>Is Available?</h1>
                    <p>{item?.isAvailable === true ? 'TRUE' : 'FALSE'}</p>
                    <h1>Is Visible?</h1>
                    <p>{item.isVisible === true ? 'TRUE' : 'FALSE'}</p>
                    <h1>Is Combo</h1>
                    <p>{item.isCombo === true ? 'TRUE' : 'FALSE'}</p>

                </>
            }
        </div>
    )
}

export default ViewComItem