import React from 'react'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import { RiCalendar2Line, RiCalendarTodoLine } from 'react-icons/ri'
import BackButton from '../../Partials/Elements/BackButton/BackButton'

function PerformanceReports() {
  return (
    // display: flex;
    // flex-direction: column;
    // gap: 20px;
    // margin-top: 40px
    <div style={{display:"flex",flexDirection:"column",marginTop:"40px",gap:"20px"}}>
      <div>
        <BackButton />
      </div>
      <div>
        <NavCardList numOfCards={'four'}>
          {/* <NavCard cardName={'Daily Reports'} navCardLink='/dailyReports' ><RiCalendarLine /></NavCard> */}
          <NavCard cardName={'Time Range Reports'} navCardLink='/timeRangeReports' ><RiCalendarTodoLine /></NavCard>
          <NavCard cardName={'Monthly Reports'} navCardLink='/monthlyRoports' ><RiCalendar2Line /></NavCard>
        </NavCardList>
      </div>
    </div>

  )
}

export default PerformanceReports