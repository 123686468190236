import { createContext, useState } from "react";
import { LOGIN_API, REGISTER_API } from "./../Utilities/APIs/AuthAPIs/AuthAPIs";

// import { useNavigate } from "react-router-dom";
import useAxiosAuthInstance from "./../Utilities/Hooks/AxiosInstanceHooks/useAxiosAuthInstance";
import toast from "react-hot-toast";

const AuthContext = createContext({});

function AuthContextProvider({ children }) {
  // const navigate = useNavigate()
  const axiosAuthInstance = useAxiosAuthInstance();

  const [employee, setEmployee] = useState(
    JSON.parse(localStorage.getItem("employee"))
  );
  // const [isLoading, setIsLoading] = useState(false)

  async function register(employeeData) {
    const toastID = toast.loading("Waiting for Response");

    try {
      const response = await axiosAuthInstance.post(REGISTER_API, employeeData);

      if (response.data) {
        setEmployee(response.data);
        localStorage.setItem("employee", JSON.stringify(response.data));
        toast.remove(toastID);
        toast.success("Login Successfully");
      }
    } catch (error) {
      toast.remove(toastID);
      toast.error(error.response.data.message);
    }
  }

  async function login(employeeData) {
    const toastID = toast.loading("Waiting for Response");
    try {
      // setIsLoading(true)
      const response = await axiosAuthInstance.post(LOGIN_API, employeeData);

      if (response.data) {
        setEmployee(response.data);
        localStorage.setItem("employee", JSON.stringify(response.data));
        toast.remove(toastID);
        toast.success("Login Successfully");
        // setIsLoading(false)
      }
    } catch (error) {
      toast.remove(toastID);
      toast.error(error.response.data.message);
    }
  }

  function logout() {
    setEmployee(null);
    localStorage.removeItem("employee");
    // navigate('/auth/login')
  }

  return (
    <AuthContext.Provider
      value={{
        register,
        login,
        logout,
        employee,
        // isLoading,
        // setIsLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContextProvider };

export default AuthContext;
