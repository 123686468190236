
import { useEffect, useState } from 'react'
import Image from '../Image/Image';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';

function Logo() {

    // const [logo,] = useState(null)
    const [logo, setLogo] = useState(null)
    
    const axiosInstance = useAxiosInstance()
    useEffect(() => {
        async function getAndSetLogo() {
            const {data} = await axiosInstance.get("/websiteContents/appSettings/manageAppSettings")
            setLogo(data[0].image)
        }
        getAndSetLogo()
    }, [axiosInstance])

    return (
        logo &&
        <Image
            imgLink={logo}
            imgAlt='Logo'
        />
    )
}

export default Logo


