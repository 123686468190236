import axios from "axios"
import { useEffect, useState } from "react"
import { ADDRESSES_API, AREAS_API } from "../../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"

function ViewAddress({

  //common props
  targetID, employee

}) {

  const [address, setAddress] = useState(null)

  const axiosInstance = useAxiosInstance()
  useEffect(() => {

    
    async function fetchAndSetAddress() {
    
        const {data} = await axiosInstance.get("customers/manageCustomer/getSingleAddressById/" + targetID)

        setAddress(data);
    }

    fetchAndSetAddress()

    

    
  }, [targetID])

  return (

    <div className="crud_view_content">

        <h1>Address Name</h1>
        <p>{address?.name}</p>

        <h1>Address Type</h1>
        <p>{address?.type}</p>

        <h1>Area</h1>
        <p>{address?.area?.name}</p>

        <h1>Full Address</h1>
        <p>{address?.fullAddress}</p>

        <h1>Zip Code</h1>
        <p>{address?.zipCode}</p>

    </div>

  )
}

export default ViewAddress