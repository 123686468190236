import React, { useEffect, useState } from "react";
import Form from "../../../Partials/Layouts/Forms/Form";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import SwitchInput from "../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { VATS_API } from "../../../../Utilities/APIs/APIs";

const UpdateVat = ({
  employee,
  targetID,
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
}) => {
  const [amount, setAmount] = useState("");
  const [isApplicable, setIsApplicable] = useState(false);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetDiscountInfo() {
      const { data } = await axiosInstance.get(
        VATS_API + "getSingleVats/" + targetID
      );
      setAmount(data.amount);
      setIsApplicable(data.isApplicable);
    }
    fetchAndSetDiscountInfo();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("amount", amount);
    itemData.append("isApplicable", isApplicable);

    const response = await axiosInstance.patch(VATS_API + targetID, itemData);

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <NumberInput
          label={`Precedence`}
          value={amount}
          placeholder={`Enter Precedence`}
          setState={setAmount}
        />
        <SwitchInput
          label={"isApplicable"}
          toggleSwitch={() => setIsApplicable((prevState) => !prevState)}
          checked={isApplicable}
        />

        <FormSubmitButton text="Update Vat" />
      </Form>
    </div>
  );
};

export default UpdateVat;
