import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../Context/AuthContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import {
  FEATURED_ITEMS_API,
  HERO_SLIDERS_API,
} from "../../../../Utilities/APIs/APIs";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CreateFeaturedItem from "./CreateFeaturedItem";
import CRUDDeleteBoilerPlate from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate";
import UpdateFeaturedItem from "./UpdateFeaturedItem";
import ViewFeaturedItem from "./ViewFeaturedItem";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import FilterSelect from "../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";

const FeaturedItems = () => {
  const [featuredItems, setFeaturedItems] = useState(null);
  const [items, setItems] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();
  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("active");

  useEffect(() => {
    async function fetchAndSetHeroSliders() {
      const { data } = await axiosInstance.get(
        `${FEATURED_ITEMS_API}?filter=${filter}`
      );
      console.log("🚀 ~ fffffffff ~ data:", data);
      setFeaturedItems(data);
    }
    fetchAndSetHeroSliders();
  }, [toggleFetch, axiosInstance, filter]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Featured Items (${featuredItems?.length} in total)`}</h1>
          </div>
          <FilterSelect filter={filter} setFilter={setFilter} />
          <CreateButton
            screenTopicSingular="Featured Items"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Precedence" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {featuredItems?.map((item) => (
              <CRUDTableRow key={item?._id}>
                <ShortTextCell text={item?.branchItem?.item?.name} />
                <ShortTextCell text={item.precedence} />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={item?._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={item?._id}
                    setTargetID={setTargetID}
                  />
                  {/* <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={item?._id}
                    setTargetID={setTargetID}
                  /> */}
                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={item._id}
                    isArchive={item?.isActive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Featured Items`) ||
            (showUpdateForm && `Update Featured Items`) ||
            (showDeleteSection && `Archive Featured Items`)
          }
        >
          {showCreateForm && (
            <CreateFeaturedItem
              employee={employee}
              items={items}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateFeaturedItem
              employee={employee}
              targetID={targetID}
              items={items}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            // <DeleteItem
            //   api={FEATURED_ITEMS_API}
            //   targetID={targetID}
            //   employee={employee}
            //   setShowModal={setShowModal}
            //   setShowDeleteSection={setShowDeleteSection}
            //   hasName
            //   triggerFetch={triggerFetch}
            // />

            <ArchiveItem
              api={FEATURED_ITEMS_API + "archiveFeaturedItem/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Featured Items`}
        >
          <ViewFeaturedItem targetID={targetID} employee={employee} />
        </Modal>
      )}
    </>
  );
};

export default FeaturedItems;
