import { useState } from "react";
import "./Pagination.css";
// import "./Pagination.css"
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

function Pagination({
  totalPages = 0,
  totalItems = 0,
  limit = 40,
  currentPage,
  setCurrentPage,
  items,
}) {
  totalPages = totalItems > 0 ? Math.ceil(totalItems / limit) : totalPages; //totalPages = totalItems > 0 ? totalItems / (limit - 1) : totalPages;
  console.log("itemsi", currentPage, "totalPages", parseInt(totalPages));

  const handlePageChange = (page) => {
    setCurrentPage(page);
    //  data fetching logic
  };

  const generatePages = () => {
    const pages = [];

    // Add first page
    pages.push(1);

    // Add ellipsis if needed before currentPage - 2
    if (currentPage > 4) {
      pages.push("...");
    }

    if (currentPage > totalPages) {
      setCurrentPage(1);
    }

    // Add visible page numbers
    for (
      let i = Math.max(2, currentPage - 2);
      i <= Math.min(totalPages - 1, currentPage + 2);
      i++
    ) {
      pages.push(parseInt(i));
    }

    // Add ellipsis if needed after currentPage + 2
    // if (currentPage < totalPages - 3) {
    //     pages.push('...');
    // }

    // Add last page
    if (totalPages > 1) {
      pages.push(parseInt(totalPages));
    }

    return pages;
  };

  const handlePageClick = (page) => {
    if (page !== "..." || page !== "") {
      handlePageChange(page);
    }
  };

  return (
    <div className="pagination-container">
      {/* left side */}
      <div className="pagination-info">
        Show {(currentPage - 1) * limit + 1} to{" "}
        {items?.length < 40 && !currentPage > 1
          ? items?.length
          : Math.min(currentPage * limit, parseInt(totalPages) * limit)}{" "}
        of{" "}
        {items?.length < 40 && !currentPage > 1
          ? items?.length
          : parseInt(totalPages) * limit}{" "}
        entries
      </div>

      {/* pagination  */}
      <input
        className="input_field"
        style={{
          maxWidth: "60px",
          marginLeft: "auto",
          marginBottom: 0,
          padding: "1.1rem .5rem",
          marginRight: ".3rem",
        }}
        type="number"
        value={currentPage}
        onChange={(e) => handlePageClick(parseInt(e.target.value))}
        max={parseInt(totalPages)}
        min={1}
      />
      <div className="pagination">
        <button
          onClick={() => handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <RiArrowLeftSLine />
        </button>
        {generatePages().map((page) => (
          <button
            key={page}
            onClick={() => {
              page !== "..." ? handlePageClick(page) : handlePageClick(3);
            }}
            className={currentPage === page ? "active" : ""}
            style={{
              backgroundColor:
                page === currentPage
                  ? "rgba(241, 101, 47, 0.8)"
                  : "var(--secodary-color)",
            }}
          >
            {page}
          </button>
        ))}
        <button
          className="prev-next-btn"
          onClick={() => handlePageClick(currentPage + 1)}
          disabled={currentPage >= parseInt(totalPages)}
        >
          <RiArrowRightSLine />
        </button>
      </div>
    </div>
  );
}

export default Pagination;
