import { useEffect, useState } from "react";
import Switch from "react-switch";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { BRANCH_CATEGORY_API, CATEGORIES_API } from "../../../../../Utilities/APIs/APIs";
import Form from "../../../../Partials/Layouts/Forms/Form";
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";




function UpdateBranchCategory({ targetID, branchID, setShowUpdateForm, setShowModal, triggerFetch,toggleFetch}) {

    const [categories, setCategories] = useState(null)
    const [category, setCategory] = useState('')
    const [precedence, setPrecedence] = useState("")

    const axiosInstance = useAxiosInstance();
    useEffect(() => {
        async function fetchAndSetCategories() {
           
            const { data } = await axiosInstance.get(BRANCH_CATEGORY_API + 'getNotAddedCategories' + '?branch=' + branchID)

            setCategories(data)
        }
        fetchAndSetCategories()
    }, [axiosInstance])

    useEffect(() => {
        async function fetchAndSetBranchCategoires() {
           
            const { data } = await axiosInstance.get(BRANCH_CATEGORY_API + "singleBranchCategoryData/" + targetID)
            setCategory(data.category?._id)
            setPrecedence(data.precedence)

        }
        fetchAndSetBranchCategoires()
    }, [axiosInstance,targetID])



    // useEffect(() => {
    //     async function fetchAndSetItem() {
    //         const { data } = await axiosInstance.get(RESTAURANTS_API + 'brances/branchItems/' + "singleBranchItemsData/" + targetID)
    //         setIsActive(data.isActive)
    //     }
    //     fetchAndSetItem()
    // }, [axiosInstance,toggleFetch])

  
    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = new FormData()
   
        itemData.append('precedence', precedence)
        itemData.append('category', category)

        const response = await axiosInstance.patch(BRANCH_CATEGORY_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }
    }
    
    return (
        <Form onSubmit={handleSubmit} hasImage>
         <SelectInput value={category} setState={setCategory}>
                <SelectOption optionValue={''} optionText={`Select Categories`} />
                {categories && categories.map(cat => (
                    <SelectOption key={cat._id} optionValue={cat._id} optionText={cat.name} />
                ))}
            </SelectInput>
            <NumberInput
                label={`Precedence`}
                value={precedence}
                placeholder={`Enter Precedence`}
                setState={setPrecedence}
            />
       
        <FormSubmitButton text='Create Branch Items' />
    </Form>
    )
}

export default UpdateBranchCategory
