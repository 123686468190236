import React, { useEffect, useState } from "react";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { DELIVERY_FEES_API, } from "../../../../Utilities/APIs/APIs";
import SwitchInput from "../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput";

const UpdateDeliveryFee = ({
  employee,
  targetID,
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
}) => {
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [isApplicable, setIsApplicable] = useState(false);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetFeaturedItemInfo() {
      const { data } = await axiosInstance.get(
        DELIVERY_FEES_API + targetID
      );
      setName(data.name);
      setAmount(data.amount);
      setIsApplicable(data.isApplicable);
      // setName(data.item.name);
      // setAmount(data.item.amount);
      // setIsApplicable(data.item.isApplicable);
    }
    fetchAndSetFeaturedItemInfo();
  }, [targetID, axiosInstance]);



  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("amount", amount);
    itemData.append("isApplicable", isApplicable);

    const response = await axiosInstance.patch(DELIVERY_FEES_API + targetID, itemData);

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <div>
      <Form onSubmit={handleSubmit} hasImage>
        <ShortTextInput
          label="Name"
          placeholder='Enter name'
          value={name}
          setState={setName}

        />
        <NumberInput
          label={`Amount`}
          value={amount}
          placeholder={`Enter Amount`}
          setState={setAmount}
        />
        {/* <ShortTextInput
                label= "Is Applicable"
                placeholder='Enter description'
                value={isApplicable}
                setState={setIsApplicable}

            /> */}
        <SwitchInput
          label={'Is Applicable'}
          toggleSwitch={() => setIsApplicable(prevState => !prevState)}
          checked={isApplicable}
        />

        <FormSubmitButton text='Update Delivery Fee' />
      </Form>
    </div>
  );
};

export default UpdateDeliveryFee;
