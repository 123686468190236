

function ThisMonthSales({thisMonthSale}) {
  return (
    <div className="card">
        <p>Total This Month Sales:</p>
        <h3>${thisMonthSale}</h3>
    </div> 
  )
}

export default ThisMonthSales