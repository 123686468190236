import { useEffect, useState } from "react"
import axios from "axios"
import Form from "../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { MANAGR_RESTAURANTS_API } from "../../../../Utilities/APIs/APIs"
import LongTextInput from "../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput"
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"
import EmailInput from "../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"


function UpdateRestaurant({

    targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {

    const [name, setName] = useState('')
    const [cuisine, setCuisine] = useState('')
    // const [description, setDescription] = useState('')
    // const [homeCardImage, setHomeCardImage] = useState('')
    // const [coverImage, setCoverImage] = useState('')
    // const [coverImage2, setCoverImage2] = useState('')
    // const [logo, setLogo] = useState('')
    // const [aboutUsImage1, setAboutUsImage1] = useState('')
    // const [aboutUsImage2, setAboutUsImage2] = useState('')
    // const [address, setAddress] = useState('')
    // const [phone, setPhone] = useState('')
    // const [email, setEmail] = useState('')

  const axiosInstance = useAxiosInstance()
    useEffect(() => {
        async function fetchAndSetRestaurant() {
            const { data } = await axiosInstance.get(MANAGR_RESTAURANTS_API + 'getSingleRestaurant/' + targetID)
            setName(data.name);
            setCuisine(data.cuisine);
            // setDescription(data.description);
            // setHomeCardImage(data.homeCardImage);
            // setCoverImage(data.coverImage);
            // setCoverImage2(data.coverImage2);
            // setLogo(data.logo);
            // setAboutUsImage1(data.aboutUsImage1);
            // setAboutUsImage2(data.aboutUsImage2);
            // setAddress(data.address);
            // setPhone(data.phone);
            // setEmail(data.email);
        }
        fetchAndSetRestaurant()
    }, [targetID,axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('cuisine', cuisine)
        // itemData.append('description', description)
        // itemData.append('homeCardImage', homeCardImage)
        // itemData.append('coverImage', coverImage)
        // itemData.append('coverImage2', coverImage2)
        // itemData.append('logo', logo)
        // itemData.append('aboutUsImage1', aboutUsImage1)
        // itemData.append('aboutUsImage2', aboutUsImage2)
        // itemData.append('address', address)
        // itemData.append('phone', phone)
        // itemData.append('email', email)

        const response = await axiosInstance.patch(MANAGR_RESTAURANTS_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label={`name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />
            {/* <LongTextInput
                label={`Description`}
                value={description}
                placeholder={`Enter Description`}
                setState={setDescription}
            /> */}
            <ShortTextInput
                label={`Cuisine`}
                value={cuisine}
                placeholder={`Enter Cuisine`}
                setState={setCuisine}
            />
            {/* <ImageInput
                fieldId='1'
                state={homeCardImage}
                setState={setHomeCardImage}
                allowUpdateImage
            >
                Upload Card Image
            </ImageInput>
            <ImageInput
                fieldId='2'
                state={coverImage}
                setState={setCoverImage}
                allowUpdateImage
            >
                Upload Cover Image
            </ImageInput>
            <ImageInput
                fieldId='3'
                state={coverImage2}
                setState={setCoverImage2}
                allowUpdateImage
            >
                Upload Cover Image2
            </ImageInput>
            <ImageInput
                fieldId='4'
                state={logo}
                setState={setLogo}
                allowUpdateImage
            >
                Upload Logo
            </ImageInput>
            <ImageInput
                fieldId='5'
                state={aboutUsImage1}
                setState={setAboutUsImage1}
                allowUpdateImage
            >
                Upload About Us Image1
            </ImageInput>
            <ImageInput
                fieldId='6'
                state={aboutUsImage2}
                setState={setAboutUsImage2}
                allowUpdateImage
            >
                Upload About Us Image2
            </ImageInput>
            <LongTextInput
                label={`Address`}
                value={address}
                placeholder={`Enter Address`}
                setState={setAddress}
            />
            <NumberInput
                label={`Phone`}
                value={phone}
                placeholder={`Enter Phone`}
                setState={setPhone}
            />
            <EmailInput
                label={`Email`}
                value={email}
                placeholder={`Enter Email`}
                setState={setEmail}
            /> */}

            <FormSubmitButton text='Update Restaurant' />
        </Form>
    )
}

export default UpdateRestaurant