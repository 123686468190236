import { ORDERS_STATUS_API } from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function CancelOrder({
  setShowModal,
  setShowCancelOrder,
  targetID,
  triggerFetch,
  employee,
}) {
  const axiosInstance = useAxiosInstance();
  async function handleCancel(id) {
    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const canceledOrder = await axiosInstance.patch(
      ORDERS_STATUS_API + "canceledOrder/" + targetID,
      config
    );

    if (canceledOrder) {
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <div className="modal_delete_content">
      <p style={{ textAlign: "center" }}>
        Are you sure you want to cancel this order?
      </p>

      <div className="modal_group_buttons">
        <button className="delete" onClick={handleCancel}>
          Delete
        </button>

        <button
          className="cancel"
          onClick={() => {
            setShowModal(false);
            setShowCancelOrder(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default CancelOrder;
