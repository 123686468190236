import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../Context/AuthContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { VATS_API } from "../../../../Utilities/APIs/APIs";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CreateVat from "./CreateVat";
import UpdateVat from "./UpdateVat";
import ViewVat from "./ViewVat";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";

const Vats = () => {
  const [vats, setVats] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetVat() {
      const { data } = await axiosInstance.get(VATS_API);
      setVats(data);
    }
    fetchAndSetVat();
  }, [toggleFetch, axiosInstance]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Vat (${vats?.length} in total)`}</h1>
          </div>

          {vats && vats.length > 0 ? (
            ""
          ) : (
            <CreateButton
              screenTopicSingular="Vat"
              setShowModal={setShowModal}
              setShowCreateForm={setShowCreateForm}
            />
          )}
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Amount" />
            <CRUDth th="IsApplicable" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {vats?.map((vat) => (
              <CRUDTableRow key={vat._id}>
                <ShortTextCell text={vat.amount} />
                <ShortTextCell text={vat.isApplicable.toString()} />

                <td className="action_button_cell">
                  {/* <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={vat._id}
                    setTargetID={setTargetID}
                  /> */}
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={vat._id}
                    setTargetID={setTargetID}
                  />
                  {/* <DeleteButton
                            setShowModal={setShowModal}
                            setShowDeleteSection={setShowDeleteSection}
                            targetID={vat._id}
                            setTargetID={setTargetID}
                        /> */}
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Vat`) ||
            (showUpdateForm && `Update Vat`) ||
            (showDeleteSection && `Delete Vat`)
          }
        >
          {showCreateForm && (
            <CreateVat
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateVat
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            // <CRUDDeleteBoilerPlate
            //   api={VATS_API}
            //   targetID={targetID}
            //   employee={employee}
            //   setShowModal={setShowModal}
            //   setShowDeleteSection={setShowDeleteSection}
            //   hasName
            //   triggerFetch={triggerFetch}
            // />
            <DeleteItem
              api={VATS_API}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View vat`}
        >
          <ViewVat targetID={targetID} employee={employee} />
        </Modal>
      )}
    </>
  );
};

export default Vats;
