import { useState, useEffect, useContext } from "react"
import { useParams } from "react-router-dom"
import AuthContext from "../../../../Context/AuthContext"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton"
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell"
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton"
import Modal from "../../../Partials/Elements/Modal/Modal"
import { FEATURED_COMBOS_API, FEATURED_ITEMS_API } from "../../../../Utilities/APIs/APIs"
import CreateFeaturedCombos from "./CreateFeaturedCombos"
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem"
import BackButton from "../../../Partials/Elements/BackButton/BackButton"



function FeaturedCombos() {
    const {parentID} =useParams()

    const [featuredCombos, setFeaturedCombos] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [showDeleteSection, setShowDeleteSection] = useState(false)
    const [toggleFetch, setToggleFetch] = useState(false)

    const { employee } = useContext(AuthContext)
    // const navigate = useNavigate()

    const axiosInstance = useAxiosInstance()

    useEffect(() => {
        async function fetchAndSetComboItems() {
            const { data } = await axiosInstance.get(FEATURED_COMBOS_API)
            setFeaturedCombos(data)
        }
        fetchAndSetComboItems()
    }, [toggleFetch, parentID, axiosInstance])

    function handleClose() {
        setShowModal(false);
        setShowCreateForm(false);
        setShowViewSection(false);
        setShowUpdateForm(false);
        setShowDeleteSection(false);
    }
    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }
    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                        <BackButton />
                    <h1 className="heading">{`Featured Combos (${featuredCombos?.length} in total)`}</h1>
                    </div>
                    <CreateButton
                        screenTopicSingular='Featured Combos'
                        setShowModal={setShowModal}
                        setShowCreateForm={setShowCreateForm}
                    />
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Items' />
                        <CRUDth th='Precedenc' />
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {featuredCombos?.map(featuredCombo => (
                            <CRUDTableRow key={featuredCombo._id}>
                                <ShortTextCell text={featuredCombo.item} />
                                <ShortTextCell text={featuredCombo.precedence} />

                                <td className="action_button_cell">
                                    {/* <ViewButton
                                        setShowModal={setShowModal}
                                        setShowViewSection={setShowViewSection}
                                        targetID={featuredCombo.item._id}
                                        setTargetID={setTargetID}
                                    /> */}
                                    {/* <EditButton
                                        setShowModal={setShowModal}
                                        setShowUpdateForm={setShowUpdateForm}
                                        targetID={comboItem._id}
                                        setTargetID={setTargetID}
                                    /> */}
                                    <DeleteButton
                                        setShowModal={setShowModal}
                                        setShowDeleteSection={setShowDeleteSection}
                                        targetID={featuredCombo._id}
                                        setTargetID={setTargetID}
                                    />

                                    {/* <CRUDButton
                                        handleClick={() => navigate('/featuredItems/' + item._id)}
                                    >
                                        <FiImage />
                                    </CRUDButton> */}

                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal &&
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (showCreateForm && `Create Featured Combos`) ||
                        (showUpdateForm && `Update Featured Combos`) ||
                        (showDeleteSection && `Delete Featured Combos`)
                    }
                >
                    {
                        showCreateForm &&
                        <CreateFeaturedCombos
                            employee={employee}
                            setShowCreateForm={setShowCreateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                            // combo={parentID}
                           
                        />
                    }
                    {/* {
                        showUpdateForm &&
                        <UpdateItem
                            employee={employee}
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    } */}
                    {
                        showDeleteSection &&
                        // <CRUDDeleteBoilerPlate
                        //     api={COMBO_ITEMS_API}
                        //     targetID={targetID}
                        //     employee={employee}
                        //     setShowModal={setShowModal}
                        //     setShowDeleteSection={setShowDeleteSection}
                        //     hasName
                        //     triggerFetch={triggerFetch}
                        // />
                        <DeleteItem
                        api={FEATURED_COMBOS_API}
                        targetID={targetID}
                        employee={employee}
                        setShowModal={setShowModal}
                        setShowDeleteSection={setShowDeleteSection}
                        hasName
                        triggerFetch={triggerFetch}
                    />
                        
                    }
                </Modal>
            }

            {/* {showModal && showViewSection &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showViewSection && `View Combo Item`)
                    }
                >
                    <ViewComItem
                        targetID={targetID}
                        employee={employee}
                    />
                </Modal>
            } */}
        </>
    )
}

export default FeaturedCombos