import { useState, useEffect, useContext } from "react";
// import { useSelector } from "react-redux"
import axios from "axios";

// import { CLIENTS_API, CONTENT_GENRES_API } from "../../../Utilities/APIs/APIs"

// import { FiImage } from "react-icons/fi"
import { useParams } from "react-router-dom";

import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
// import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton"
import Modal from "../../../Partials/Elements/Modal/Modal";
import CRUDDeleteBoilerPlate from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate";
import { ITEMS_API, MANAGE_ITEM_API } from "../../../../Utilities/APIs/APIs";
import CreateItem from "./CreateItem";
import ViewItem from "./ViewItem";
import UpdateItem from "./UpdateItem";
import AuthContext from "../../../../Context/AuthContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";
import FilterSelect from "../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import Search from "../../../Partials/Elements/Search/Search";
import FilterCategory from "./FilterCategory/FilterCategory";
// import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton"

function Items() {
  const [items, setItems] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");

  const axiosInstance = useAxiosInstance();
  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("active");
  const { employee } = useContext(AuthContext);
  // const navigate = useNavigate()
  const { parentID } = useParams();

  useEffect(() => {
    async function fetchAndSetItems() {
      const { data } = await axiosInstance.get(
        `${MANAGE_ITEM_API}getItemFromRestaurant/${parentID}?filter=${filter}&search=${search}&category=${category}`
      );
      setItems(data);
    }
    fetchAndSetItems();
  }, [toggleFetch, parentID, axiosInstance, filter, search, category]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Items (${items?.length} in total)`}</h1>
          </div>
          <Search value={search} setState={setSearch} />
          <FilterSelect filter={filter} setFilter={setFilter} />
          <FilterCategory category={category} setCategory={setCategory} />
          <CreateButton
            screenTopicSingular="Item"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Image" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {items?.map((item) => (
              <CRUDTableRow key={item._id}>
                <ShortTextCell text={item.name} />
                <ImageCell imgSrc={item.image} />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={item._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={item._id}
                    setTargetID={setTargetID}
                  />
                  {/* <DeleteButton
                                        setShowModal={setShowModal}
                                        setShowDeleteSection={setShowDeleteSection}
                                        targetID={item._id}
                                        setTargetID={setTargetID}
                                    /> */}

                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={item._id}
                    isArchive={item?.isActive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Item`) ||
            (showUpdateForm && `Update Item`) ||
            (showDeleteSection && `Archive Item`)
          }
        >
          {showCreateForm && (
            <CreateItem
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              restaurantID={parentID}
            />
          )}
          {showUpdateForm && (
            <UpdateItem
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              restaurantID={parentID}
            />
          )}
          {showDeleteSection && (


            <ArchiveItem
              api={MANAGE_ITEM_API + "archiveItem/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Item`}
        >
          <ViewItem targetID={targetID} employee={employee} />
        </Modal>
      )}
    </>
  );
}

export default Items;
