import { useEffect, useState } from "react"
import axios from "axios"
import { EMPLOYEES_API, MANAGE_BRANCH_API, MANAGE_EMPLOYEE_API, MANAGR_RESTAURANTS_API, RESTAURANT_MANAGER_API } from "../../../../Utilities/APIs/APIs"
import Form from "../../../Partials/Layouts/Forms/Form";

import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function CreateRestaurantManager({

    employee, targetID, setShowRestaurantManager, setShowModal, triggerFetch

}) {
    console.log(targetID);
    const [branch, setBranch] = useState('')
    const [branchs, setBranchs] = useState(null)
    const [checkManager, setCheckManager] = useState(null)
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetBranchs() {
            const { data } = await axiosInstance.get(MANAGE_BRANCH_API)
            setBranchs(data.branch)
        }
        fetchAndSetBranchs()
    }, [axiosInstance])

    useEffect(() => {
        async function fetchAndSetCheckManager() {
            const { data } = await axiosInstance.get(MANAGE_EMPLOYEE_API + 'checkManagerAssignedOrNot/' + targetID,)
            setCheckManager(data)
        }
        fetchAndSetCheckManager()
    }, [targetID, employee.token])


    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = { employee: targetID, branch }
        // let response;
        // response = await axiosInstance.post("/manageEmployees/employeeBranches", itemData)
        // if (checkManager?.branch) {
        //     response = await axiosInstance.patch('/manageEmployees/employeeBranches/' + targetID, itemData)
        // }
        // if (!checkManager?.branch) {
        //     response = await axiosInstance.post("/manageEmployees/employeeBranches", itemData)
        // }


        let response;

        if (checkManager?.branch) {
            response = await axiosInstance.patch('/manageEmployees/employeeBranches/' + targetID, itemData);
        } else {
            response = await axiosInstance.post("/manageEmployees/employeeBranches", itemData);
        }

        if (response) {
            setShowRestaurantManager(false);
            setShowModal(false);
            triggerFetch()
        }
    }


    console.log("check manager is", checkManager)

    return (
        <>
            <h1>Assigned to:</h1>
            {
                checkManager?.branch ? <p>{checkManager?.branch}</p> : 'Not Assigned to any restaurant'
            }
            <br />

            <Form onSubmit={handleSubmit} >
                <h1>Select Restaurant to Assign</h1>
                <SelectInput value={branch} setState={setBranch}>
                    <SelectOption optionValue={''} optionText={`Select Branch`} />
                    {branchs && branchs.map(restaurant => (
                        <SelectOption key={restaurant._id} optionValue={restaurant._id} optionText={restaurant.name} />
                    ))}
                </SelectInput>


                <FormSubmitButton text='Assign' />
            </Form>
        </>
    )
}

export default CreateRestaurantManager