import { useState, useEffect, useContext } from "react";

// import { CLIENTS_API, CONTENT_GENRES_API } from "../../../Utilities/APIs/APIs"
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";

import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
// import DeleteButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton"

import Modal from "../../../Partials/Elements/Modal/Modal";
import { MANAGR_RESTAURANTS_API } from "../../../../Utilities/APIs/APIs";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import { FiImage } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import ViewRestaurant from "./ViewRestaurant";
import CreateRestaurat from "./CreateRestaurant";
import UpdateRestaurant from "./UpdateRestaurant";
import AuthContext from "../../../../Context/AuthContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import FilterSelect from "../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import AppContext from "../../../../Context/AppContext";
import useLevels from "../../../../Utilities/Permissions/useLevels";

function RestaurantScreen() {
  const { myBranch } = useContext(AppContext);
  const [restaurants, setRestaurants] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { superAdmin, owner, branchManager } = useLevels();
  const axiosInstance = useAxiosInstance();
  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("active");
  const { employee } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetRestaurants() {
      const { data } = await axiosInstance.get(
        `${MANAGR_RESTAURANTS_API}?filter=${filter}`
      );
      setRestaurants(data);
    }
    fetchAndSetRestaurants();
  }, [toggleFetch, axiosInstance, filter]);
  console.log(restaurants);
  console.log(myBranch);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }
  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  // console.log("filter is ",filter)
  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Restaurants (${restaurants?.length} in total)`}</h1>
          </div>

          <FilterSelect filter={filter} setFilter={setFilter} />

          <CreateButton
            screenTopicSingular="Restaurant"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Cuisine" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {restaurants?.map((restaurant) => (
              <CRUDTableRow key={restaurant._id}>
                <ShortTextCell text={restaurant?.name} />
                <ShortTextCell text={restaurant?.cuisine} />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={restaurant._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={restaurant._id}
                    setTargetID={setTargetID}
                  />
                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={restaurant._id}
                    isArchive={restaurant?.isArchive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />
                  {/* <DeleteButton
                                        setShowModal={setShowModal}
                                        setShowDeleteSection={setShowDeleteSection}
                                        targetID={restaurant._id}
                                        setTargetID={setTargetID}
                                    /> */}
                  <CRUDButton
                    dataTip=" Details"
                    dataFor=" Details Item"
                    handleClick={() =>
                      navigate("/restaurantInfoScreen/" + restaurant._id)
                    }
                  >
                    <FiImage />
                  </CRUDButton>
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Restaurant`) ||
            (showUpdateForm && `Update Restaurant`) ||
            (showDeleteSection && `Archive Restaurant`)
          }
        >
          {showCreateForm && (
            <CreateRestaurat
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateRestaurant
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showDeleteSection && (
            //     <DeleteItem
            //     api={MANAGR_RESTAURANTS_API}
            //     targetID={targetID}
            //     employee={employee}
            //     setShowModal={setShowModal}
            //     setShowDeleteSection={setShowDeleteSection}
            //     hasName
            //     triggerFetch={triggerFetch}
            // />

            <ArchiveItem
              api={MANAGR_RESTAURANTS_API + "archiveRestaurant/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Restaurant`}
        >
          <ViewRestaurant targetID={targetID} employee={employee} />
        </Modal>
      )}
    </>
  );
}

export default RestaurantScreen;
