import { useContext, useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import AuthContext from "../../../../Context/AuthContext";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import { EMERALD_AREAS_API, EMERALD_ZONES_API } from "../../../../Utilities/APIs/APIs";
import CreateEmeraldAreas from "./CreateEmeraldAreas";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import ViewEmeraldAreas from "./ViewEmeraldAreas";
import UpdateEmeraldAreas from "./UpdateEmeraldAreas";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";
import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import FilterSelect from "../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import { useParams } from "react-router-dom";

const EmeraldAreas = () => {
  const {zoneID} = useParams()
  const [emeraldAreas, setEmeraldAreas] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();
  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("active");
  useEffect(() => {
    async function fetchAndSetRestaurantAreas() {
      const { data } = await axiosInstance.get(
        `${EMERALD_ZONES_API}getEmeraldAreasByZone/${zoneID}?filter=${filter}`
      );
      setEmeraldAreas(data);
    }
    fetchAndSetRestaurantAreas();
  }, [toggleFetch, axiosInstance, filter,zoneID]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Emerald Areas (${EmeraldAreas?.length} in total)`}</h1>
          </div>

          <FilterSelect filter={filter} setFilter={setFilter} />
          <CreateButton
            screenTopicSingular="Emerald Areas"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Emerald Area Name" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {emeraldAreas?.map((area) => (
              <CRUDTableRow key={area._id}>
                <ShortTextCell text={area.name} />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={area._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={area._id}
                    setTargetID={setTargetID}
                  />
                  {/* <DeleteButton
                        setShowModal={setShowModal}
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={area._id}
                        setTargetID={setTargetID}
                    /> */}

                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={area._id}
                    isArchive={area?.isActive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Emerald Areas`) ||
            (showUpdateForm && `Update Emerald Areas`) ||
            (showDeleteSection && `Archive Emerald Areas`)
          }
        >
          {showCreateForm && (
            <CreateEmeraldAreas
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              zoneID={zoneID}
            />
          )}
          {showUpdateForm && (
            <UpdateEmeraldAreas
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            //   <DeleteItem
            //   api={EMERALD_AREAS_API}
            //   targetID={targetID}
            //   employee={employee}
            //   setShowModal={setShowModal}
            //   setShowDeleteSection={setShowDeleteSection}
            //   hasName
            //   triggerFetch={triggerFetch}
            // />

            <ArchiveItem
              api={EMERALD_AREAS_API + "archiveEmeraldArea/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Restaurant Area`}
        >
          <ViewEmeraldAreas targetID={targetID} employee={employee} />
        </Modal>
      )}
    </>
  );
};

export default EmeraldAreas;
