import { RiGroupLine, RiUserShared2Line } from "react-icons/ri"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"
import useLevels from "../../../Utilities/Permissions/useLevels"
import BackButton from "../../Partials/Elements/BackButton/BackButton"

function EmployeesScreen() {
  const { superAdmin } = useLevels()
  return (
<div style={{display:"flex",flexDirection:"column",paddingTop:"30px",height:"89dvh"}}>
    <div style={{paddingBottom: "30px"}}>
      <BackButton />
    </div>
    <NavCardList numOfCards={'four'}>
      <NavCard cardName={'Employees'} navCardLink='/employees' ><RiGroupLine /></NavCard>
      <NavCard cardName={'Employee Invites'} navCardLink='/employeeInvites' ><RiUserShared2Line /></NavCard>
      <NavCard cardName={'All Managers'} navCardLink='/allManagers' ><RiGroupLine /></NavCard>

      {
        (superAdmin) &&
        <NavCard cardName={'All Owners'} navCardLink='/allOwners' ><RiGroupLine /></NavCard>
      }

    </NavCardList>
    </div>



  
  )
}

export default EmployeesScreen