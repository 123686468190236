import React from 'react'
import { RiBlazeLine, RiCollageLine, RiImage2Line } from 'react-icons/ri'
import { useParams } from 'react-router-dom'
import NavCardList from '../../../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../../../Partials/Layouts/NavCardList/NavCard/NavCard'
import BackButton from '../../../../Partials/Elements/BackButton/BackButton'

function BranchScreen() {

    const {parentID} =useParams()

    return (

        <div style={{display:"flex",flexDirection:"column",marginTop:"40px",gap:"20px"}}>
        <div>
          <BackButton />
        </div>
         <NavCardList numOfCards={'four'}>
            <NavCard cardName={'Branch Area'} navCardLink={'/branchArea/' + parentID }><RiCollageLine /></NavCard>
            {/* <NavCard cardName={'Branch Items'} navCardLink={'/branchItems/' + parentID} ><RiImage2Line /></NavCard> */}
            <NavCard cardName={'Branch Category'} navCardLink={'/branchCategory/' + parentID} ><RiBlazeLine /></NavCard>
        </NavCardList>
        </div>
       
    )
}

export default BranchScreen