import React, { useContext } from 'react'

import axios from 'axios'
import { EMPLOYEES_API, MANAGE_EMPLOYEE_API } from '../../../../Utilities/APIs/APIs'
import AuthContext from '../../../../Context/AuthContext'
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance'
// import { useSelector } from 'react-redux';
function RemoveOwner({
  setShowModal, setShowRemoveOwner, targetID, triggerFetch
}) {

    const axiosInstance = useAxiosInstance()

  async function handleRemove(e) {
      e.preventDefault()

    const response = await axiosInstance.patch(MANAGE_EMPLOYEE_API + 'createOwnerToEmployeeBySuperAdmin/' + targetID, {})

    if (response) {
      setShowModal(false);
      triggerFetch()
    }
  }
  return (
    <div className='modal_delete_content'>
      <p style={{ textAlign: "center" }}>
        Are you sure you want to make employee this owner?

      </p>

      <div className="modal_group_buttons">
        <button
          className="delete"
          onClick={handleRemove}
        >Make Employee</button>

        <button className="cancel" onClick={() => {
          setShowModal(false);
          setShowRemoveOwner(false);
          
        }}>Cancel</button>
      </div>

    </div>
  )
}

export default RemoveOwner