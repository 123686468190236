

function YesterdaySales({yesterdaySale}) {
  return (
    <div className="card">
    <p>Total Yesterday Sales:</p>
    <h3>${yesterdaySale}</h3>
</div> 
  )
}

export default YesterdaySales