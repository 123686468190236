import { useState, useEffect } from "react";
import { IMAGE_URL, MANAGE_EMPLOYEE_API } from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function ViewEmployee({
  //common props
  targetID,
}) {
  const [managerInfo, setManagerInfo] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetManagerInfo() {
      
      const { data } = await axiosInstance.get(
       MANAGE_EMPLOYEE_API + "getSingleEmployee/" + targetID );
      setManagerInfo(data);
    }
    fetchAndSetManagerInfo();
  }, [targetID,axiosInstance])

  return (
    <div className="crud_view_content">
      {managerInfo && (
        <>
          <h1>Name</h1>
          <p>{managerInfo.name}</p>
          <h1>Image</h1>
          <img src={IMAGE_URL + managerInfo.dp} alt="" />
          <h1>Email</h1>
          <p>{managerInfo.email}</p>
          <h1>Level</h1>
          <p>{managerInfo.level}</p>
        </>
      )}
    </div>
  );
}

export default ViewEmployee;
