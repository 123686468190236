import { useContext, useEffect } from "react";
import { useState } from "react";
import Switch from "react-switch";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../../../Context/AuthContext";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import {
  BRANCH_CATEGORY_API,
  BRANCH_ITMS_API,
  MANAGE_BRANCH_API,
} from "../../../../../Utilities/APIs/APIs";
import CreateButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import EditButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import CreateBranchCategory from "./CreateBranchCategory";
import UpdateBranchCategory from "./UpdateBranchCategory";
import DeleteCategoryBranch from "./DeleteCategoryBranch";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import ViewBranchCategory from "./ViewBranchCategory";
import CRUDButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import { FiImage } from "react-icons/fi";
import BackButton from "../../../../Partials/Elements/BackButton/BackButton";
import ArchiveButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import ArchiveItem from "../../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import FilterSelect from "../../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";

const BranchCategory = () => {
  const { branchID } = useParams();
  const [branchCategories, setBranchCategories] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [branch, setBranch] = useState(null)

  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("active");

  const { employee } = useContext(AuthContext);
  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetBranch() {
      const { data } = await axiosInstance.get(MANAGE_BRANCH_API + 'singleBranchData/' + branchID)
      setBranch(data);
    }
    fetchAndSetBranch()
  }, [targetID, axiosInstance])

  useEffect(() => {
    async function fetchAndSetBranches() {
      //   const { data } = await axiosInstance.get(BRANCH_AREAS_API + "getBranchesByRestaurant/" + branchID
      const { data } = await axiosInstance.get(
        BRANCH_CATEGORY_API + "getAllbranchCategoryByBranch/" + branchID + "?filter=" + filter
      );

      //   console.log(data?.emeraldArea);
      setBranchCategories(data);
    }
    fetchAndSetBranches();
  }, [toggleFetch, axiosInstance, branchID, filter]);


  async function handleIsActiveToggle(branchCategoryId, currentStatus) {
    try {
      await axiosInstance.patch(`${BRANCH_CATEGORY_API}activeBranchCategory/${branchCategoryId}`, { isActive: !currentStatus });
      triggerFetch()
    } catch (error) {
      console.error("Failed to update branch item status:", error);
    }
  };

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`${branch && branch?.branch?.name} - Categories (${branchCategories?.length} in total)`}</h1>
          </div>
          <FilterSelect filter={filter} setFilter={setFilter} />
          <CreateButton
            screenTopicSingular="Branch Category"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Branch Category" />

            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {branchCategories?.length > 0 &&
              branchCategories?.map((category) => (
                <CRUDTableRow key={category._id}>
                  <ShortTextCell text={category?.category?.name} />


                  <td className="action_button_cell">
                    <ViewButton
                      setShowModal={setShowModal}
                      setShowViewSection={setShowViewSection}
                      targetID={category._id}
                      setTargetID={setTargetID}
                    />
                    <ArchiveButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={category._id}
                      isArchive={category?.isActive}
                      setTargetID={setTargetID}
                      setArchive={setArchive}
                    />
                    {/* <EditButton
                      setShowModal={setShowModal}
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={category._id}
                      setTargetID={setTargetID}
                    /> */}
                    <CRUDButton
                      dataTip="Branch Item"
                      dataFor="Branch Item"
                      handleClick={() =>
                        navigate(
                          "/branchItems/" +
                          branchID + "/" +
                          category.category.name +
                          "/" +
                          category.category._id
                        )
                      }
                    >
                      <FiImage />
                    </CRUDButton>
                    {/* <DeleteButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={branch._id}
                      setTargetID={setTargetID}
                    /> */}
                  </td>
                </CRUDTableRow>
              ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Branch Categories`) ||
            (showUpdateForm && `Update Branch Categories`) ||
            (showDeleteSection && `Delete Branch Categories`)
          }
        >
          {showCreateForm && (
            <CreateBranchCategory
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              branchID={branchID}
            />
          )}
          {showUpdateForm && (
            <UpdateBranchCategory
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              branchID={branchID}
              toggleFetch={toggleFetch}
            />
          )}

          {showDeleteSection && (
            <ArchiveItem
              api={BRANCH_CATEGORY_API + "activeBranchCategory/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Branch Category`}
        >
          <ViewBranchCategory targetID={targetID} employee={employee} />
        </Modal>
      )}
    </>
  );
};

export default BranchCategory;
